<template>
  <div>
    <div class="header header-transparent dark-text">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="@/assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#login"
                    class="crs_yuo12 w-auto text-white theme-bg"
                  >
                    <span class="embos_45"
                      ><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">
              <li
                :class="curNavId === item.id ? 'active' : ''"
                v-for="item in navs"
                :key="'nav_' + item.id"
              >
                <a @click="navJump(item)"
                  >{{ item.name }}<span class="submenu-indicator"></span
                ></a>
                <ul class="nav-dropdown nav-submenu" v-if="item.children">
                  <li v-for="chItem in item.children" :key="'nav_' + chItem.id">
                    <a @click="navJump(chItem, item)">{{ chItem.name }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="nav-menu nav-menu-social align-to-right">
              <li>
                <a
                  @click="showLoginDialog"
                  class="alio_green"
                  data-toggle="modal"
                  data-target="#login"
                >
                  <i class="fas fa-sign-in-alt mr-1"></i
                  ><span class="dn-lg">登录</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a @click="jump('signup')" class="text-white">注册</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div class="clearfix"></div>
    <qr-login ref="qr-login"></qr-login>
  </div>
</template>

<script>
import QrLogin from "@/components/qr-login";
export default {
  name: "top-header",
  components: { QrLogin },
  data() {
    return {
      navs: [],
      curNavId: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.navs = require("@/assets/data/nav.json");
    });
  },
  methods: {
    showLoginDialog() {
      this.$refs["qr-login"].show();
    },
    closeLoginDialog() {
      this.$refs["qr-login"].close();
    },
    navJump(navItem, parent) {
      this.curNavId = parent ? parent.id : navItem.id;
      if (navItem.url) this.$router.push(navItem.url);
    },
    jump(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
</style>
