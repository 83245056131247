import { createApp } from 'vue';
import $router from "@/router";
import $store from "@/store";
import App from './App.vue';
import ElementPlus from "element-plus";
import { ElMessage, ElMessageBox } from "element-plus";
import "@/assets/css/styles.css";
import "element-plus/dist/index.css";
const apis = require("@/scripts/apis");
const app = createApp(App);
app.use($router).use($store).use(ElementPlus);
// region $message 的封装
const newMsg = ElMessage;
["success", "warning", "info", "error"].forEach((type) => {
    newMsg[type] = (options) => {
        options.dangerouslyUseHTMLString = true;
        options.duration = 3000;
        app.config.globalProperties['$' + type] = newMsg(options);
        return newMsg(options);
    };
});
app.config.globalProperties.$message = newMsg;
// endregion $message 的封装
// region $messageBox 的封装
const newMsgBox = ElMessageBox;
["alert", "confirm", "prompt"].forEach((type) => {
    newMsgBox[type] = (options) => {
        options.dangerouslyUseHTMLString = true;
        return newMsgBox(options);
    };
});
app.config.globalProperties.$msgbox = newMsgBox;
app.config.globalProperties.$messageBox = newMsgBox;
app.config.globalProperties.$confirm = newMsgBox.confirm;
app.config.globalProperties.$alert = newMsgBox.alert;
app.config.globalProperties.$prompt = newMsgBox.prompt;
// endregion $messageBox 的封装
app.config.globalProperties.$apis = apis;
app.mount('#app');
