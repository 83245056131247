import { createRouter, createWebHashHistory } from "vue-router";

export default createRouter({
    history: createWebHashHistory(""),
    routes: [
        { path: "/", redirect: "/index" },
        // region 模板列表
        { path: "/templates/:type/:title", component: require("@/pages/templates").default },
        // endregion 模板列表
        { path: "/404", component: require("@/pages/404").default },
        { path: "/about", component: require("@/pages/about").default },
        { path: "/add-admin", component: require("@/pages/add-admin").default },
        { path: "/add-instructor", component: require("@/pages/add-instructor").default },
        { path: "/add-new-course", component: require("@/pages/add-new-course").default },
        { path: "/add-students", component: require("@/pages/add-students").default },
        { path: "/add-to-cart", component: require("@/pages/add-to-cart").default },
        { path: "/addon-manager", component: require("@/pages/addon-manager").default },
        { path: "/admin-revenue", component: require("@/pages/admin-revenue").default },
        { path: "/blog-detail", component: require("@/pages/blog-detail").default },
        { path: "/blogs", component: require("@/pages/blogs").default },
        { path: "/checkout", component: require("@/pages/checkout").default },
        { path: "/component", component: require("@/pages/component").default },
        { path: "/concat", component: require("@/pages/concat").default },
        { path: "/coupons", component: require("@/pages/coupons").default },
        { path: "/course-category", component: require("@/pages/course-category").default },
        { path: "/course-detail-2", component: require("@/pages/course-detail-2").default },
        { path: "/course-detail-3", component: require("@/pages/course-detail-3").default },
        { path: "/course-detail-4", component: require("@/pages/course-detail-4").default },
        { path: "/course-detail", component: require("@/pages/course-detail").default },
        { path: "/dash-about", component: require("@/pages/dash-about").default },
        { path: "/dashboard", component: require("@/pages/dashboard").default },
        { path: "/element", component: require("@/pages/element").default },
        { path: "/enroll-history", component: require("@/pages/enroll-history").default },
        { path: "/enroll-student", component: require("@/pages/enroll-student").default },
        { path: "/explore-category", component: require("@/pages/explore-category").default },
        { path: "/faq", component: require("@/pages/faq").default },
        { path: "/find-instructor", component: require("@/pages/find-instructor").default },
        { path: "/forgot", component: require("@/pages/forgot").default },
        { path: "/grid-layout-full-2", component: require("@/pages/grid-layout-full-2").default },
        { path: "/grid-layout-full-3", component: require("@/pages/grid-layout-full-3").default },
        { path: "/grid-layout-full", component: require("@/pages/grid-layout-full").default },
        { path: "/grid-layout-with-sidebar-2", component: require("@/pages/grid-layout-with-sidebar-2").default },
        { path: "/grid-layout-with-sidebar-3", component: require("@/pages/grid-layout-with-sidebar-3").default },
        { path: "/grid-layout-with-sidebar-4", component: require("@/pages/grid-layout-with-sidebar-4").default },
        { path: "/grid-layout-with-sidebar-5", component: require("@/pages/grid-layout-with-sidebar-5").default },
        { path: "/grid-layout-with-sidebar", component: require("@/pages/grid-layout-with-sidebar").default },
        { path: "/home-2", component: require("@/pages/home-2").default },
        { path: "/home-3", component: require("@/pages/home-3").default },
        { path: "/home-4", component: require("@/pages/home-4").default },
        { path: "/home-5", component: require("@/pages/home-5").default },
        { path: "/home-6", component: require("@/pages/home-6").default },
        { path: "/home-7", component: require("@/pages/home-7").default },
        { path: "/index", component: require("@/pages/index").default },
        { path: "/instructor-detail", component: require("@/pages/instructor-detail").default },
        { path: "/instructor-payout", component: require("@/pages/instructor-payout").default },
        { path: "/instructor-revenue", component: require("@/pages/instructor-revenue").default },
        { path: "/list-layout-with-full", component: require("@/pages/list-layout-with-full").default },
        { path: "/list-layout-with-sidebar", component: require("@/pages/list-layout-with-sidebar").default },
        { path: "/list-shop-with-sidebar", component: require("@/pages/list-shop-with-sidebar").default },
        { path: "/login", component: require("@/pages/login").default },
        { path: "/manage-admins", component: require("@/pages/manage-admins").default },
        { path: "/manage-course", component: require("@/pages/manage-course").default },
        { path: "/manage-instructor", component: require("@/pages/manage-instructor").default },
        { path: "/manage-students", component: require("@/pages/manage-students").default },
        { path: "/messages", component: require("@/pages/messages").default },
        { path: "/my-profile", component: require("@/pages/my-profile").default },
        { path: "/payment-settings", component: require("@/pages/payment-settings").default },
        { path: "/pricing", component: require("@/pages/pricing").default },
        { path: "/product-detail", component: require("@/pages/product-detail").default },
        { path: "/shop-with-right-sidebar", component: require("@/pages/shop-with-right-sidebar").default },
        { path: "/shop-with-sidebar", component: require("@/pages/shop-with-sidebar").default },
        { path: "/signup", component: require("@/pages/signup").default },
        { path: "/smtp-setting", component: require("@/pages/smtp-setting").default },
        { path: "/social-login", component: require("@/pages/social-login").default },
        { path: "/system-settings", component: require("@/pages/system-settings").default },
        { path: "/themes", component: require("@/pages/themes").default },
        { path: "/website-settings", component: require("@/pages/website-settings").default },
        { path: "/wishlist", component: require("@/pages/wishlist").default },
    ],
});
