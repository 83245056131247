import Axios from "axios"
import {getVueServiceEnvValue} from "@/scripts/common";
const axios = Axios.create();
const baseUrl = getVueServiceEnvValue("base_url");
axios.interceptors.request.use(config => {
    let url = config.url;
    // url是完整地址，包含http:// 或 https://，则不添加baseUrl
    if (url.indexOf("http://")+url.indexOf("https://") === -2) config.url = baseUrl + url;
    return config;
}, error => {
   return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    let code = response.data.code;
    response.data.success = code === 0 || code === "0";
    return response;
}, error => {
    return Promise.reject(error);
});

export {
    axios
}
