<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header theme-bg light-menu">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/light-logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12 w-auto text-white theme-bg">
                    <span class="embos_45"><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing bg-white">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->


    <!-- ============================ Hero Banner  Start================================== -->
    <div class="hero_banner image-cover image_bottom h7_bg">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="simple-search-wrap text-left">
              <div class="hero_search-2">
                <div class="elsio_tag yellow">LISTEN TO OUR NEW ANTHEM</div>
                <h1 class="banner_title mb-4">The Best<br>e-Learning Cources For<br><span class="light">Better Future</span></h1>
                <p class="font-lg mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                <div class="input-group simple_search">
                  <i class="fa fa-search ico"></i>
                  <input type="text" class="form-control" placeholder="Search Your Cources">
                  <div class="input-group-append">
                    <button class="btn theme-bg" type="button">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="side_block extream_img">
              <div class="list_crs_img">
                <img src="assets/img/ic-1.png" class="img-fluid cirl animate-fl-y" alt="" />
                <img src="assets/img/ic-2.png" class="img-fluid arrow animate-fl-x" alt="" />
                <img src="assets/img/ic-3.png" class="img-fluid moon animate-fl-x" alt="" />
              </div>
              <img src="assets/img/side-2.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

    <!-- ============================ Cources Start ================================== -->
    <section>
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Featured Cources <span class="theme-cl">For You</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Development</span>
                  <span class="est st_2">PHP</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">Advance PHP knowledge with laravel to make smart web</h4>
                  <span class="crs_auth"><i>By</i> Adam Wilson</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">99</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Insurence</span>
                  <span class="est st_2">Banking</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">The complete accounting & bank financial course 2021</h4>
                  <span class="crs_auth"><i>By</i> Mike Hussey</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">139</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Software</span>
                  <span class="est st_2">Technology</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">The complete business plan course includes 50 templates</h4>
                  <span class="crs_auth"><i>By</i> Litha Joshi</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">77.99</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Business</span>
                  <span class="est st_2">Finance</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">Full web designing course with 20 web template designing</h4>
                  <span class="crs_auth"><i>By</i> Adam Wilson</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">129</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8 mt-2">
            <div class="text-center"><a href="grid-layout-with-sidebar.html" class="btn btn-md theme-bg-light theme-cl">Explore More Cources</a></div>
          </div>
        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Cources End ================================== -->

    <!-- ============================ Instructor Start ================================== -->
    <section class="min gray">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Best Cources by Top <span class="theme-cl">Instructor</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>Physics Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">Alisha P. Paradis</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(244 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.5k Users Enrolled</div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>History Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">Melissa A. Maynard</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(119 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 3.2k Users Enrolled</div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>Hindi Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">Isaias C. Poovey</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(96 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.2k Users Enrolled</div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>Math Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">Vivian E. Winders</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(149 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 3.1k Users Enrolled</div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>Bio Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">Jeffery C. Watson</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(204 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.3k Users Enrolled</div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>Chemistry Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">Sean K. Leon</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(74 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 1.5k Users Enrolled</div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>Sociology Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">Gertrude D. Shorter</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(96 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 1.2k Users Enrolled</div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="crs_trt_grid">
              <div class="crs_trt_thumb circle">
                <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid circle" alt=""></a>
              </div>
              <div class="crs_trt_caption">
                <div class="instructor_tag dark"><span>Regining Teacher</span></div>
                <div class="instructor_title"><h4><a href="instructor-detail.html">David L. Garza</a></h4></div>
                <div class="trt_rate_inf">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star-half filled"></i>
                  <span class="alt_rates">(73 Reviews)</span>
                </div>
              </div>
              <div class="crs_trt_footer">
                <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.1k Users Enrolled</div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Instructor End ================================== -->

    <!-- ============================ Top Cources E-books ================================== -->
    <section class="min">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Top & Featured <span class="theme-cl">Instructor</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="prd_label new">New</div>
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="prd_label hot">Hot</div>
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="prd_label new">New</div>
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div class="prd_grid_box">
              <div class="prd_label hot">Hot</div>
              <div class="shd_142">
                <div class="prt_saveed_12lk">
                  <label class="toggler toggler-danger"><input type="checkbox"><i class="fas fa-heart"></i></label>
                </div>
              </div>
              <div class="prd_grid_thumb">
                <a href="product-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid" alt=""></a>
              </div>
              <div class="prd_grid_caption">
                <div class="prd_center_capt">
                  <div class="prd_review">
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star filled"></i>
                    <i class="fa fa-star"></i>
                    <span>(34)</span>
                  </div>
                  <div class="prd_title"><h4>Harry Porter 5th</h4></div>
                  <div class="prd_price_info">
                    <h5 class="org_prc"><span class="old_prc">$220</span>$149</h5>
                  </div>
                </div>
                <div class="prd_bot_capt">
                  <div class="prd_button">
                    <a href="product-detail.html" class="bth bth_prd">Add To Cart</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <!-- ============================ Top Cources E-books ================================== -->

    <!-- ============================ Students Reviews ================================== -->
    <section class="gray">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Our Students <span class="theme-cl">Reviews</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 col-sm-12">

            <div class="reviews-slide space">

              <!-- Single Item -->
              <div class="single_items lios_item">
                <div class="_testimonial_wrios shadow_none">
                  <div class="_testimonial_flex">
                    <div class="_testimonial_flex_first">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/500x500" class="img-fluid" alt="">
                      </div>
                      <div class="_tsl_flex_capst">
                        <h5>Susan D. Murphy</h5>
                        <div class="_ovr_posts"><span>CEO, Leader</span></div>
                        <div class="_ovr_rates"><span><i class="fa fa-star"></i></span>4.7</div>
                      </div>
                    </div>
                    <div class="_testimonial_flex_first_last">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/300x300" class="img-fluid" alt="">
                      </div>
                    </div>
                  </div>

                  <div class="facts-detail">
                    <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="single_items lios_item">
                <div class="_testimonial_wrios shadow_none">
                  <div class="_testimonial_flex">
                    <div class="_testimonial_flex_first">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/500x500" class="img-fluid" alt="">
                      </div>
                      <div class="_tsl_flex_capst">
                        <h5>Maxine E. Gagliardi</h5>
                        <div class="_ovr_posts"><span>Apple CEO</span></div>
                        <div class="_ovr_rates"><span><i class="fa fa-star"></i></span>4.5</div>
                      </div>
                    </div>
                    <div class="_testimonial_flex_first_last">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/300x300" class="img-fluid" alt="">
                      </div>
                    </div>
                  </div>

                  <div class="facts-detail">
                    <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="single_items lios_item">
                <div class="_testimonial_wrios shadow_none">
                  <div class="_testimonial_flex">
                    <div class="_testimonial_flex_first">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/500x500" class="img-fluid" alt="">
                      </div>
                      <div class="_tsl_flex_capst">
                        <h5>Roy M. Cardona</h5>
                        <div class="_ovr_posts"><span>Google Founder</span></div>
                        <div class="_ovr_rates"><span><i class="fa fa-star"></i></span>4.9</div>
                      </div>
                    </div>
                    <div class="_testimonial_flex_first_last">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/300x300" class="img-fluid" alt="">
                      </div>
                    </div>
                  </div>

                  <div class="facts-detail">
                    <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="single_items lios_item">
                <div class="_testimonial_wrios shadow_none">
                  <div class="_testimonial_flex">
                    <div class="_testimonial_flex_first">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/500x500" class="img-fluid" alt="">
                      </div>
                      <div class="_tsl_flex_capst">
                        <h5>Dorothy K. Shipton</h5>
                        <div class="_ovr_posts"><span>Linkedin Leader</span></div>
                        <div class="_ovr_rates"><span><i class="fa fa-star"></i></span>4.7</div>
                      </div>
                    </div>
                    <div class="_testimonial_flex_first_last">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/300x300" class="img-fluid" alt="">
                      </div>
                    </div>
                  </div>

                  <div class="facts-detail">
                    <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="single_items lios_item">
                <div class="_testimonial_wrios shadow_none">
                  <div class="_testimonial_flex">
                    <div class="_testimonial_flex_first">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/500x500" class="img-fluid" alt="">
                      </div>
                      <div class="_tsl_flex_capst">
                        <h5>Robert P. McKissack</h5>
                        <div class="_ovr_posts"><span>CEO, Leader</span></div>
                        <div class="_ovr_rates"><span><i class="fa fa-star"></i></span>4.7</div>
                      </div>
                    </div>
                    <div class="_testimonial_flex_first_last">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/300x300" class="img-fluid" alt="">
                      </div>
                    </div>
                  </div>

                  <div class="facts-detail">
                    <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </section>
    <!-- ============================ Students Reviews End ================================== -->

    <!-- ============================ Work Process Start ================================== -->
    <section>
      <div class="container">

        <div class="row align-items-center justify-content-between">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="lmp_caption">
              <h2 class="mb-3">We Have The Best Instructors Available in The City</h2>
              <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique</p>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Full lifetime access</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">20+ downloadable resources</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Certificate of completion</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Free Trial 7 Days</h6>
                </div>
              </div>
              <div class="text-left mt-4"><a href="#" class="btn btn-md text-light theme-bg">Enrolled Today</a></div>
            </div>
          </div>

          <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <div class="side_block extream_img">
              <div class="list_crs_img">
                <img src="assets/img/img-1.png" class="img-fluid elsio cirl animate-fl-y" alt="" />
                <img src="assets/img/img-3.png" class="img-fluid elsio arrow animate-fl-x" alt="" />
                <img src="assets/img/img-2.png" class="img-fluid elsio moon animate-fl-x" alt="" />
              </div>
              <img src="assets/img/st-3.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt-0">
      <div class="container">
        <div class="row align-items-center justify-content-between mt-5">
          <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <div class="lmp_thumb">
              <img src="assets/img/news.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="lmp_caption">
              <span class="theme-cl">Join over 25,000 Global Students</span>
              <h2 class="mb-3">Get Instant alert on your inbox</h2>
              <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique</p>
              <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus</p>
              <div class="foot-news-last mt-4">
                <div class="input-group">
                  <input type="text" class="form-control brd" placeholder="Email Address">
                  <div class="input-group-append">
                    <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Work Process End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "home-7"
}
</script>

<style scoped>

</style>
