const {axios} = require("@/scripts/request");

/**
 * 获取所有的模板分类
 * @returns 
 */
exports.getAllTemplateCategories = () => axios.get("notice/findAllNoticeType");

/**
 * 获取微信登录二维码
 * @returns 
 */
exports.getLoginQRCode = () => axios.get("wechat/createQrcode");

/**
 * 获取通知模板列表
 * @param {Object} params 
 * @returns 
 */
exports.getTemplates = (params) => axios.post("notice/findNoticeModelByCondition", params);