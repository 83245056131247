<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-transparent dark-text">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12 w-auto text-white theme-bg">
                    <span class="embos_45"><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->


    <!-- ============================ Hero Banner  Start================================== -->
    <div class="hero_banner image-cover image_bottom h4_bg">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="simple-search-wrap text-left">
              <div class="hero_search-2">
                <h1 class="banner_title mb-4">Find the most exciting online cources</h1>
                <p class="font-lg mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                <div class="input-group simple_search">
                  <i class="fa fa-search ico"></i>
                  <input type="text" class="form-control" placeholder="Search Your Cources">
                  <div class="input-group-append">
                    <button class="btn theme-bg" type="button">Search</button>
                  </div>
                </div>
                <div class="crs_trio_info">
                  <div class="crs_trio">
                    <h4 class="ctr"><span class="rt_count">72</span>K</h4>
                    <span class="pol_title">Appreciations</span>
                  </div>
                  <div class="crs_trio">
                    <h4 class="ctr"><span class="rt_count">100</span></h4>
                    <span class="pol_title">Countries</span>
                  </div>
                  <div class="crs_trio">
                    <h4 class="ctr"><span class="rt_count">5.2</span>K +</h4>
                    <span class="pol_title">Cources</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="side_block">
              <img src="assets/img/side-2.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

    <!-- ================================ Tag Award ================================ -->
    <section class="p-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="crp_box fl_color ovr_top">
              <div class="row align-items-center">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141"><i class="fa fa-journal-whills"></i></div>
                    <div class="dro_142">
                      <h6>200+ Cources</h6>
                      <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141 st-1"><i class="fa fa-business-time"></i></div>
                    <div class="dro_142">
                      <h6>Lifetime Access</h6>
                      <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141 st-3"><i class="fa fa-user-shield"></i></div>
                    <div class="dro_142">
                      <h6>800k+ Enrolled</h6>
                      <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- ================================ Tag Award ================================ -->

    <!-- ============================ Featured Categories Start ================================== -->
    <section class="min">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Explore Featured <span class="theme-cl">Categories</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>


        <div class="row justify-content-center">
          <!-- Single Category -->
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-code"></i></div>
                <div class="crs_cate_caption"><span>Development</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-window-restore"></i></div>
                <div class="crs_cate_caption"><span>Web Designing</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-leaf"></i></div>
                <div class="crs_cate_caption"><span>Lifestyle</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-heartbeat"></i></div>
                <div class="crs_cate_caption"><span>Health & Fitness</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-landmark"></i></div>
                <div class="crs_cate_caption"><span>Gov. Exams</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-photo-video"></i></div>
                <div class="crs_cate_caption"><span>Photography</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-stamp"></i></div>
                <div class="crs_cate_caption"><span>Finance & Accounting</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="crs_cate_wrap style_2">
              <a href="grid-layout-with-sidebar.html" class="crs_cate_box">
                <div class="crs_cate_icon"><i class="fa fa-school"></i></div>
                <div class="crs_cate_caption"><span>Office Productivity</span></div>
                <div class="crs_cate_count"><span>22 Lectures</span></div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Featured Categories End ================================== -->

    <!-- ============================ Latest Cources Start ================================== -->
    <section class="gray">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Find Featured <span class="theme-cl">Cources</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <!-- Single Grid -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="crs_grid">
              <div class="crs_grid_thumb">
                <a href="course-detail.html" class="crs_detail_link">
                  <img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt="" />
                </a>
                <div class="crs_video_ico">
                  <i class="fa fa-play"></i>
                </div>
                <div class="crs_locked_ico">
                  <i class="fa fa-lock"></i>
                </div>
              </div>
              <div class="crs_grid_caption">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_cates"><span>Design</span></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">149</span></h2></div>
                  </div>
                </div>
                <div class="crs_title"><h4><a href="course-detail.html" class="crs_title_link">UI/UX Design pattern for succesfull software Applications</a></h4></div>
                <div class="crs_info_detail">
                  <ul>
                    <li><i class="fa fa-clock text-danger"></i><span>10 hr 07 min</span></li>
                    <li><i class="fa fa-video text-success"></i><span>67 Lectures</span></li>
                  </ul>
                </div>
              </div>
              <div class="crs_grid_foot">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_tutor">
                      <div class="crs_tutor_thumb"><a href="instructor-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid circle" alt="" /></a></div>
                    </div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="foot_list_info">
                      <ul class="light">
                        <li><div class="elsio_ic"><i class="fa fa-user text-danger"></i></div><div class="elsio_tx">4.7k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-eye text-success"></i></div><div class="elsio_tx">42.4k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-star text-warning"></i></div><div class="elsio_tx">4.7</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Grid -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="crs_grid">
              <div class="crs_grid_thumb">
                <a href="course-detail.html" class="crs_detail_link">
                  <img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt="" />
                </a>
                <div class="crs_video_ico">
                  <i class="fa fa-play"></i>
                </div>
                <div class="crs_locked_ico">
                  <i class="fa fa-lock"></i>
                </div>
              </div>
              <div class="crs_grid_caption">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_cates cl_3"><span>Accounting</span></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">129</span></h2></div>
                  </div>
                </div>
                <div class="crs_title"><h4><a href="course-detail.html" class="crs_title_link">Basic knowledge about hodiernal bharat in history</a></h4></div>
                <div class="crs_info_detail">
                  <ul>
                    <li><i class="fa fa-clock text-danger"></i><span>04 hr 07 min</span></li>
                    <li><i class="fa fa-video text-success"></i><span>27 Lectures</span></li>
                  </ul>
                </div>
              </div>
              <div class="crs_grid_foot">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_tutor">
                      <div class="crs_tutor_thumb"><a href="instructor-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid circle" alt="" /></a></div>
                    </div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="foot_list_info">
                      <ul class="light">
                        <li><div class="elsio_ic"><i class="fa fa-user text-danger"></i></div><div class="elsio_tx">4.7k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-eye text-success"></i></div><div class="elsio_tx">42.4k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-star text-warning"></i></div><div class="elsio_tx">4.7</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Grid -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="crs_grid">
              <div class="crs_grid_thumb">
                <a href="course-detail.html" class="crs_detail_link">
                  <img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt="" />
                </a>
                <div class="crs_video_ico">
                  <i class="fa fa-play"></i>
                </div>
                <div class="crs_locked_ico">
                  <i class="fa fa-lock"></i>
                </div>
              </div>
              <div class="crs_grid_caption">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_cates cl_4"><span>Physics</span></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">99</span></h2></div>
                  </div>
                </div>
                <div class="crs_title"><h4><a href="course-detail.html" class="crs_title_link">Advance PHP knowledge with laravel to make smart web</a></h4></div>
                <div class="crs_info_detail">
                  <ul>
                    <li><i class="fa fa-clock text-danger"></i><span>07 hr 10 min</span></li>
                    <li><i class="fa fa-video text-success"></i><span>32 Lectures</span></li>
                  </ul>
                </div>
              </div>
              <div class="crs_grid_foot">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_tutor">
                      <div class="crs_tutor_thumb"><a href="instructor-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid circle" alt="" /></a></div>
                    </div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="foot_list_info">
                      <ul class="light">
                        <li><div class="elsio_ic"><i class="fa fa-user text-danger"></i></div><div class="elsio_tx">4.7k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-eye text-success"></i></div><div class="elsio_tx">42.4k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-star text-warning"></i></div><div class="elsio_tx">4.7</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Grid -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="crs_grid">
              <div class="crs_grid_thumb">
                <a href="course-detail.html" class="crs_detail_link">
                  <img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt="" />
                </a>
                <div class="crs_video_ico">
                  <i class="fa fa-play"></i>
                </div>
                <div class="crs_locked_ico">
                  <i class="fa fa-lock"></i>
                </div>
              </div>
              <div class="crs_grid_caption">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_cates cl_5"><span>Business</span></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">139</span></h2></div>
                  </div>
                </div>
                <div class="crs_title"><h4><a href="course-detail.html" class="crs_title_link">The complete accounting & bank financial course 2021</a></h4></div>
                <div class="crs_info_detail">
                  <ul>
                    <li><i class="fa fa-clock text-danger"></i><span>06 hr 20 min</span></li>
                    <li><i class="fa fa-video text-success"></i><span>28 Lectures</span></li>
                  </ul>
                </div>
              </div>
              <div class="crs_grid_foot">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_tutor">
                      <div class="crs_tutor_thumb"><a href="instructor-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid circle" alt="" /></a></div>
                    </div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="foot_list_info">
                      <ul class="light">
                        <li><div class="elsio_ic"><i class="fa fa-user text-danger"></i></div><div class="elsio_tx">4.7k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-eye text-success"></i></div><div class="elsio_tx">42.4k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-star text-warning"></i></div><div class="elsio_tx">4.7</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Grid -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="crs_grid">
              <div class="crs_grid_thumb">
                <a href="course-detail.html" class="crs_detail_link">
                  <img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt="" />
                </a>
                <div class="crs_video_ico">
                  <i class="fa fa-play"></i>
                </div>
                <div class="crs_locked_ico">
                  <i class="fa fa-lock"></i>
                </div>
              </div>
              <div class="crs_grid_caption">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_cates cl_6"><span>Finance</span></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">99</span></h2></div>
                  </div>
                </div>
                <div class="crs_title"><h4><a href="course-detail.html" class="crs_title_link">The complete business plan course includes 50 templates</a></h4></div>
                <div class="crs_info_detail">
                  <ul>
                    <li><i class="fa fa-clock text-danger"></i><span>04 hr 17 min</span></li>
                    <li><i class="fa fa-video text-success"></i><span>18 Lectures</span></li>
                  </ul>
                </div>
              </div>
              <div class="crs_grid_foot">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_tutor">
                      <div class="crs_tutor_thumb"><a href="instructor-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid circle" alt="" /></a></div>
                    </div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="foot_list_info">
                      <ul class="light">
                        <li><div class="elsio_ic"><i class="fa fa-user text-danger"></i></div><div class="elsio_tx">4.7k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-eye text-success"></i></div><div class="elsio_tx">42.4k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-star text-warning"></i></div><div class="elsio_tx">4.7</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Single Grid -->
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="crs_grid">
              <div class="crs_grid_thumb">
                <a href="course-detail.html" class="crs_detail_link">
                  <img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt="" />
                </a>
                <div class="crs_video_ico">
                  <i class="fa fa-play"></i>
                </div>
                <div class="crs_locked_ico">
                  <i class="fa fa-lock"></i>
                </div>
              </div>
              <div class="crs_grid_caption">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_cates cl_7"><span>Banking</span></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">199</span></h2></div>
                  </div>
                </div>
                <div class="crs_title"><h4><a href="course-detail.html" class="crs_title_link">Full web designing course with 20 web template designing</a></h4></div>
                <div class="crs_info_detail">
                  <ul>
                    <li><i class="fa fa-clock text-danger"></i><span>10 hr 42 min</span></li>
                    <li><i class="fa fa-video text-success"></i><span>74 Lectures</span></li>
                  </ul>
                </div>
              </div>
              <div class="crs_grid_foot">
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_tutor">
                      <div class="crs_tutor_thumb"><a href="instructor-detail.html"><img src="https://via.placeholder.com/500x500" class="img-fluid circle" alt="" /></a></div>
                    </div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="foot_list_info">
                      <ul class="light">
                        <li><div class="elsio_ic"><i class="fa fa-user text-danger"></i></div><div class="elsio_tx">4.7k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-eye text-success"></i></div><div class="elsio_tx">42.4k</div></li>
                        <li><div class="elsio_ic"><i class="fa fa-star text-warning"></i></div><div class="elsio_tx">4.7</div></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8 mt-2">
            <div class="text-center"><a href="grid-layout-with-sidebar.html" class="btn btn-md theme-bg-light theme-cl">Explore More Cources</a></div>
          </div>
        </div>

      </div>
    </section>
    <!-- ============================ Latest Cources End ================================== -->

    <!-- ============================ Work Process Start ================================== -->
    <section class="imageblock">
      <div class="imageblock__content">
        <div class="background-image-holder" style="background: url(https://via.placeholder.com/1920x1200);">
          <img alt="image" src="https://via.placeholder.com/1920x1200">
        </div>
      </div>
      <div class="container">

        <div class="row align-items-center justify-content-between">
          <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
            <div class="lmp_caption">
              <h2 class="mb-3">We Have The Best Instructors Available in The City</h2>
              <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique</p>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Full lifetime access</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">20+ downloadable resources</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Certificate of completion</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Free Trial 7 Days</h6>
                </div>
              </div>
              <div class="text-left mt-4"><a href="#" class="btn btn-md text-light theme-bg">Enrolled Today</a></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="imageblock pt-m-0">
      <div class="imageblock__content left">
        <div class="background-image-holder" style="background: url(https://via.placeholder.com/1920x1200);">
          <img alt="image" src="https://via.placeholder.com/1920x1200">
        </div>
      </div>
      <div class="container">
        <div class="row align-items-center justify-content-end">
          <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <div class="lmp_caption">
              <ol class="list-unstyled p-0">
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center gray">
                    <div class="position-absolute theme-cl h5 mb-0"><i class="fa fa-photo-video"></i></div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>Easy Uploads Lectures</h4>
                    <p>
                      Oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center gray">
                    <div class="position-absolute theme-cl h5 mb-0"><i class="fa fa-edit"></i></div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>Customize According You</h4>
                    <p>
                      Error sit voluptatem actium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center gray">
                    <div class="position-absolute theme-cl h5 mb-0"><i class="fa fa-question-circle"></i></div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>Ask Unlimited Questions</h4>
                    <p>
                      Error sit voluptatem actium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center gray">
                    <div class="position-absolute theme-cl h5 mb-0"><i class="fa fa-thumbs-up"></i></div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>100% Satisfactions</h4>
                    <p>
                      Unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Work Process End ================================== -->

    <!-- ============================ Our Instructor Start ================================== -->
    <section class="gray">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-10 text-center">
            <div class="sec-heading center mb-4">
              <h2>Our Top <span class="theme-cl">Instructors</span></h2>
              <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="tutor-slide">

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid shadow_none brd">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag"><span>Hindi Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Rodney T. Doyon</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                  <div class="crs_trt_footer">
                    <div class="crs_flex">
                      <div class="crs_fl_first">
                        <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.5k Users Enrolled</div>
                      </div>
                      <div class="crs_fl_last">
                        <div class="foot_list_info">
                          <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid shadow_none brd">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag"><span>Sanskrit Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Luella J. Robles</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                  <div class="crs_trt_footer">
                    <div class="crs_flex">
                      <div class="crs_fl_first">
                        <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.5k Users Enrolled</div>
                      </div>
                      <div class="crs_fl_last">
                        <div class="foot_list_info">
                          <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid shadow_none brd">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag"><span>Math Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">David E. Lampkin</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                  <div class="crs_trt_footer">
                    <div class="crs_flex">
                      <div class="crs_fl_first">
                        <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.5k Users Enrolled</div>
                      </div>
                      <div class="crs_fl_last">
                        <div class="foot_list_info">
                          <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid shadow_none brd">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag"><span>History Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Michael B. Maxwell</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                  <div class="crs_trt_footer">
                    <div class="crs_flex">
                      <div class="crs_fl_first">
                        <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.5k Users Enrolled</div>
                      </div>
                      <div class="crs_fl_last">
                        <div class="foot_list_info">
                          <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid shadow_none brd">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag"><span>PHP Developer</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Linda R. Gibson</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                  <div class="crs_trt_footer">
                    <div class="crs_flex">
                      <div class="crs_fl_first">
                        <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.5k Users Enrolled</div>
                      </div>
                      <div class="crs_fl_last">
                        <div class="foot_list_info">
                          <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid shadow_none brd">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag"><span>Chemistry Expert</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Douglas M. Mikel</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                  <div class="crs_trt_footer">
                    <div class="crs_flex">
                      <div class="crs_fl_first">
                        <div class="crs_trt_ent"><i class="fa fa-user"></i> 2.5k Users Enrolled</div>
                      </div>
                      <div class="crs_fl_last">
                        <div class="foot_list_info">
                          <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- ============================ Our Instructor End ================================== -->

    <!-- ============================ Pricing Table ================================== -->
    <section class="min">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Select Your <span class="theme-cl">Package</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row align-items-center">

          <!-- Single Package -->
          <div class="col-lg-4 col-md-4">
            <div class="pricing_wrap">
              <div class="prt_head">
                <h4>Basic</h4>
              </div>
              <div class="prt_price">
                <h2><span>$</span>29</h2>
                <span>per user, per month</span>
              </div>
              <div class="prt_body">
                <ul>
                  <li>99.5% Uptime Guarantee</li>
                  <li>120GB CDN Bandwidth</li>
                  <li>5GB Cloud Storage</li>
                  <li class="none">Personal Help Support</li>
                  <li class="none">Enterprise SLA</li>
                </ul>
              </div>
              <div class="prt_footer">
                <a href="#" class="btn choose_package">Start Basic</a>
              </div>
            </div>
          </div>

          <!-- Single Package -->
          <div class="col-lg-4 col-md-4">
            <div class="pricing_wrap">
              <div class="prt_head">
                <div class="recommended">Best Value</div>
                <h4>Standard</h4>
              </div>
              <div class="prt_price">
                <h2><span>$</span>49</h2>
                <span>per user, per month</span>
              </div>
              <div class="prt_body">
                <ul>
                  <li>99.5% Uptime Guarantee</li>
                  <li>150GB CDN Bandwidth</li>
                  <li>10GB Cloud Storage</li>
                  <li>Personal Help Support</li>
                  <li class="none">Enterprise SLA</li>
                </ul>
              </div>
              <div class="prt_footer">
                <a href="#" class="btn choose_package active">Start Standard</a>
              </div>
            </div>
          </div>

          <!-- Single Package -->
          <div class="col-lg-4 col-md-4">
            <div class="pricing_wrap">
              <div class="prt_head">
                <h4>Platinum</h4>
              </div>
              <div class="prt_price">
                <h2><span>$</span>79</h2>
                <span>2 user, per month</span>
              </div>
              <div class="prt_body">
                <ul>
                  <li>100% Uptime Guarantee</li>
                  <li>200GB CDN Bandwidth</li>
                  <li>20GB Cloud Storage</li>
                  <li>Personal Help Support</li>
                  <li>Enterprise SLA</li>
                </ul>
              </div>
              <div class="prt_footer">
                <a href="#" class="btn choose_package">Start Platinum</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <!-- ============================ Pricing Table End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "home-4"
}
</script>

<style scoped>

</style>
