/**
 * 格式化数量，转成 999,999,999格式
 * @param inNum 输入数字
 * @return {string}
 */
const formatterCount = function (inNum) {
    if (!inNum) return "0";
    let inStr =inNum.toString().split('.');
    let n = inStr[0];
    let outStr = "";
    for (let i = 0; i < n.length ; i++) {
        let delta = n.length-1 - i;
        outStr += n[i];
        if (n.length-1 !== i && delta % 3 === 0) {
            outStr += ",";
        }
    }
    if (inStr.length === 2) outStr+= "." + inStr[1];
    return outStr;
};
/**
 * 获取 exe 的环境变量
 * @param key
 * @return {string|null}
 */
const getExecuteEnvValue = function (key) {
    if (!key) return null;
    if (window.process) {
        let env = window.process.env;
        if (!env) {
            return null;
        }
        return getObjValueIgnoreUpperOrLower(env, key);
    }
    return null
}

/**
 * 获取 node 进程的环境变量
 * @param key
 * @return {string|null}
 */
const getNodeEnvValue = function (key) {
    if (!key) return null;
    if (process) {
        let env = process.env;
        if (!env) {
            return null;
        }
        return getObjValueIgnoreUpperOrLower(env, key);
    }
    return null
}
/**
 * 获取 Vue-cli-service 进程的环境变量
 * @param key
 * @return {string|null}
 */
const getVueServiceEnvValue = function (key) {
    if (!key) return null;
    if (process) {
        let env = process.env;
        if (!env) {
            return null;
        }
        let val = null
        if (key.indexOf("VUE_APP_") === 0) {
            val = getObjValueIgnoreUpperOrLower(env, key);
        } else {
            val = getObjValueIgnoreUpperOrLower(env, "VUE_APP_"+key);
        }
        if (val === "yes") {
            val = true;
        } else if (val === "no") {
            val = false;
        }
        return val;
    }
    return null
}

/**
 * 从对象中查找属性值，忽略属性key的大小写
 * @param obj
 * @param key
 * @return {string|*}
 */
const getObjValueIgnoreUpperOrLower = function (obj, key) {
    let keys = Object.keys(obj);
    for (let i = 0; i <keys.length; i++) {
        let cur = keys[i];
        if (cur.toUpperCase() === key.toString().toUpperCase()) {
            return obj[cur];
        }
    }
    return "";
}

/**
 * base64转Uint8Array
 * @param base64Str
 * @return {Uint8Array}
 */
const base64ToUint8Array = function (base64Str){
    if (base64Str) {
        if (base64Str.indexOf(",")!==-1) {
            base64Str = base64Str.split(",")[1];
        }
        let raw = window.atob(base64Str);
        let len = raw.length;
        let buf = new Uint8Array(new ArrayBuffer(len));
        for (let i = 0; i < len; i++) {
            buf[i] = raw.charCodeAt(i);
        }
        return buf;
    }
    return  new Uint8Array(new ArrayBuffer(0));
}
export {
    getExecuteEnvValue,
    getNodeEnvValue,
    getVueServiceEnvValue,
    base64ToUint8Array,
    formatterCount
}
