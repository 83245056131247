<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-light">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li class="account-drop">
                  <a href="javascript:void(0);" class="crs_yuo12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="embos_45"><i class="fas fa-shopping-basket"></i><i class="embose_count">4</i></span>
                  </a>
                  <div class="dropdown-menu pull-right animated flipInX">
                    <div class="drp_menu_headr bg-purple">
                      <h4>Wishlist Product</h4>
                    </div>
                    <div class="ground-list ground-hover-list">
                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Web History<small class="float-right text-fade">$120</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Physics Beginning<small class="float-right text-fade">$99</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Computer Fundamental<small class="float-right text-fade">$99</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Computer Advance<small class="float-right text-fade">$49</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <button type="button" class="btn theme-bg text-white full-width">Go To Cart</button>
                      </div>

                    </div>
                  </div>
                </li>
                <li class="account-drop">
                  <a href="javascript:void(0);" class="crs_yuo12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="embos_45"><i class="fas fa-bell"></i><i class="embose_count red">3</i></span>
                  </a>
                  <div class="dropdown-menu pull-right animated flipInX">
                    <div class="drp_menu_headr bg-warning">
                      <h4>22 Notifications</h4>
                    </div>
                    <div class="ground-list ground-hover-list">
                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-success">
                          <div class="position-absolute text-success h5 mb-0"><i class="fas fa-user"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Maryam Amiri</a></h6>
                          <small class="text-fade">New User Enrolled in Python</small>
                          <span class="small">Just Now</span>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-danger">
                          <div class="position-absolute text-danger h5 mb-0"><i class="fas fa-comments"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Shilpa Rana</a></h6>
                          <small class="text-fade">Shilpa Send a Message</small>
                          <span class="small">02 Min Ago</span>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-info">
                          <div class="position-absolute text-info h5 mb-0"><i class="fas fa-grin-squint-tears"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Amar Muskali</a></h6>
                          <small class="text-fade">Need Responsive Business Tem...</small>
                          <span class="small">10 Min Ago</span>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-purple">
                          <div class="position-absolute text-purple h5 mb-0"><i class="fas fa-briefcase"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Maryam Amiri</a></h6>
                          <small class="text-fade">Next Meeting on Tuesday..</small>
                          <span class="small">15 Min Ago</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </li>

                <li>
                  <div class="btn-group account-drop">
                    <a href="javascript:void(0);" class="crs_yuo12 btn btn-order-by-filt" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="https://via.placeholder.com/500x500" class="avater-img" alt="">
                    </a>
                    <div class="dropdown-menu pull-right animated flipInX">
                      <div class="drp_menu_headr">
                        <h4>Hi, Daniel</h4>
                      </div>
                      <ul>
                        <li><a href="dashboard.html"><i class="fa fa-tachometer-alt"></i>Dashboard<span class="notti_coun style-1">4</span></a></li>
                        <li><a href="my-profile.html"><i class="fa fa-user-tie"></i>My Profile</a></li>
                        <li><a href="manage-course.html"><i class="fas fa-shopping-basket"></i>Manage Courses<span class="notti_coun style-2">7</span></a></li>
                        <li><a href="manage-instructor.html"><i class="fas fa-toolbox"></i>Manage Instructor</a></li>
                        <li><a href="manage-students.html"><i class="fa fa-envelope"></i>Manage Students<span class="notti_coun style-3">3</span></a></li>
                        <li><a href="messages.html"><i class="fas fa-comments"></i>Messages</a></li>
                        <li><a href="login.html"><i class="fa fa-unlock-alt"></i>Sign Out</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper" style="transition-property: none;">
            <ul class="nav-menu">

              <li><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li class="active"><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li class="account-drop">
                <a href="javascript:void(0);" class="crs_yuo12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="embos_45"><i class="fas fa-shopping-basket"></i><i class="embose_count">4</i></span>
                </a>
                <div class="dropdown-menu pull-right animated flipInX">
                  <div class="drp_menu_headr bg-purple">
                    <h4>Wishlist Product</h4>
                  </div>
                  <div class="ground-list ground-hover-list">
                    <div class="ground ground-list-single">
                      <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                      <div class="ground-content">
                        <h6>Web History<small class="float-right text-fade">$120</small></h6>
                        <a href="#" class="small text-danger">Remove</a>
                      </div>
                    </div>

                    <div class="ground ground-list-single">
                      <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                      <div class="ground-content">
                        <h6>Physics Beginning<small class="float-right text-fade">$99</small></h6>
                        <a href="#" class="small text-danger">Remove</a>
                      </div>
                    </div>

                    <div class="ground ground-list-single">
                      <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                      <div class="ground-content">
                        <h6>Computer Fundamental<small class="float-right text-fade">$99</small></h6>
                        <a href="#" class="small text-danger">Remove</a>
                      </div>
                    </div>

                    <div class="ground ground-list-single">
                      <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                      <div class="ground-content">
                        <h6>Computer Advance<small class="float-right text-fade">$49</small></h6>
                        <a href="#" class="small text-danger">Remove</a>
                      </div>
                    </div>

                    <div class="ground ground-list-single">
                      <button type="button" class="btn theme-bg text-white full-width">Go To Cart</button>
                    </div>

                  </div>
                </div>
              </li>
              <li class="account-drop">
                <a href="javascript:void(0);" class="crs_yuo12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="embos_45"><i class="fas fa-bell"></i><i class="embose_count red">3</i></span>
                </a>
                <div class="dropdown-menu pull-right animated flipInX">
                  <div class="drp_menu_headr bg-warning">
                    <h4>22 Notifications</h4>
                  </div>
                  <div class="ground-list ground-hover-list">
                    <div class="ground ground-list-single">
                      <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-success">
                        <div class="position-absolute text-success h5 mb-0"><i class="fas fa-user"></i></div>
                      </div>

                      <div class="ground-content">
                        <h6><a href="#">Maryam Amiri</a></h6>
                        <small class="text-fade">New User Enrolled in Python</small>
                        <span class="small">Just Now</span>
                      </div>
                    </div>

                    <div class="ground ground-list-single">
                      <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-danger">
                        <div class="position-absolute text-danger h5 mb-0"><i class="fas fa-comments"></i></div>
                      </div>

                      <div class="ground-content">
                        <h6><a href="#">Shilpa Rana</a></h6>
                        <small class="text-fade">Shilpa Send a Message</small>
                        <span class="small">02 Min Ago</span>
                      </div>
                    </div>

                    <div class="ground ground-list-single">
                      <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-info">
                        <div class="position-absolute text-info h5 mb-0"><i class="fas fa-grin-squint-tears"></i></div>
                      </div>

                      <div class="ground-content">
                        <h6><a href="#">Amar Muskali</a></h6>
                        <small class="text-fade">Need Responsive Business Tem...</small>
                        <span class="small">10 Min Ago</span>
                      </div>
                    </div>

                    <div class="ground ground-list-single">
                      <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-purple">
                        <div class="position-absolute text-purple h5 mb-0"><i class="fas fa-briefcase"></i></div>
                      </div>

                      <div class="ground-content">
                        <h6><a href="#">Maryam Amiri</a></h6>
                        <small class="text-fade">Next Meeting on Tuesday..</small>
                        <span class="small">15 Min Ago</span>
                      </div>
                    </div>

                  </div>
                </div>
              </li>
              <li>
                <div class="btn-group account-drop">
                  <a href="javascript:void(0);" class="crs_yuo12 btn btn-order-by-filt" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="https://via.placeholder.com/500x500" class="avater-img" alt="">
                  </a>
                  <div class="dropdown-menu pull-right animated flipInX">
                    <div class="drp_menu_headr">
                      <h4>Hi, Daniel</h4>
                    </div>
                    <ul>
                      <li><a href="dashboard.html"><i class="fa fa-tachometer-alt"></i>Dashboard<span class="notti_coun style-1">4</span></a></li>
                      <li><a href="my-profile.html"><i class="fa fa-user-tie"></i>My Profile</a></li>
                      <li><a href="manage-course.html"><i class="fas fa-shopping-basket"></i>Manage Courses<span class="notti_coun style-2">7</span></a></li>
                      <li><a href="manage-instructor.html"><i class="fas fa-toolbox"></i>Manage Instructor</a></li>
                      <li><a href="manage-students.html"><i class="fa fa-envelope"></i>Manage Students<span class="notti_coun style-3">3</span></a></li>
                      <li><a href="messages.html"><i class="fas fa-comments"></i>Messages</a></li>
                      <li><a href="login.html"><i class="fa fa-unlock-alt"></i>Sign Out</a></li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Dashboard: Dashboard Start ================================== -->
    <section class="gray pt-4">
      <div class="container-fluid">

        <div class="row">

          <div class="col-lg-3 col-md-3">
            <div class="dashboard-navbar">

              <div class="d-user-avater">
                <img src="https://via.placeholder.com/500x500" class="img-fluid avater" alt="">
                <h4>Adam Harshvardhan</h4>
                <span>Senior Designer</span>
                <div class="elso_syu89">
                  <ul>
                    <li><a href="#"><i class="ti-facebook"></i></a></li>
                    <li><a href="#"><i class="ti-twitter"></i></a></li>
                    <li><a href="#"><i class="ti-instagram"></i></a></li>
                    <li><a href="#"><i class="ti-linkedin"></i></a></li>
                  </ul>
                </div>
                <div class="elso_syu77">
                  <div class="one_third"><div class="one_45ic text-warning bg-light-warning"><i class="fas fa-star"></i></div><span>Ratings</span></div>
                  <div class="one_third"><div class="one_45ic text-success bg-light-success"><i class="fas fa-file-invoice"></i></div><span>Courses</span></div>
                  <div class="one_third"><div class="one_45ic text-purple bg-light-purple"><i class="fas fa-user"></i></div><span>Enrolled User</span></div>
                </div>
              </div>

              <div class="d-navigation">
                <ul id="side-menu">
                  <li class="active"><a href="dashboard.html"><i class="fas fa-th"></i>Dashboard</a></li>
                  <li class="dropdown">
                    <a href="javascript:void(0);"><i class="fas fa-shopping-basket"></i>Courses<span class="ti-angle-left"></span></a>
                    <ul class="nav nav-second-level">
                      <li><a href="manage-course.html">Manage Courses</a></li>
                      <li><a href="add-new-course.html">Add New Course</a></li>
                      <li><a href="course-category.html">Course Category</a></li>
                      <li><a href="coupons.html">Coupons</a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="javascript:void(0);"><i class="fas fa-gem"></i>Enrollment<span class="ti-angle-left"></span></a>
                    <ul class="nav nav-second-level">
                      <li><a href="enroll-history.html">Enroll History</a></li>
                      <li><a href="enroll-student.html">Enroll a Student</a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="javascript:void(0);"><i class="fas fa-archive"></i>Report<span class="ti-angle-left"></span></a>
                    <ul class="nav nav-second-level">
                      <li><a href="admin-revenue.html">Admin Revenue</a></li>
                      <li><a href="instructor-revenue.html">Instructor Revenue</a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="javascript:void(0);"><i class="fas fa-user-shield"></i>Admins<span class="ti-angle-left"></span></a>
                    <ul class="nav nav-second-level">
                      <li><a href="manage-admins.html">Manage Admins</a></li>
                      <li><a href="add-admin.html">Add New Admins</a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="javascript:void(0);"><i class="fas fa-toolbox"></i>Instructors<span class="ti-angle-left"></span></a>
                    <ul class="nav nav-second-level">
                      <li><a href="manage-instructor.html">Manage Instructors</a></li>
                      <li><a href="add-instructor.html">Add New Instructors</a></li>
                      <li><a href="instructor-payout.html">Instructors Payouts</a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="javascript:void(0);"><i class="fas fa-user"></i>Students<span class="ti-angle-left"></span></a>
                    <ul class="nav nav-second-level">
                      <li><a href="manage-students.html">Manage Students</a></li>
                      <li><a href="add-students.html">Add New Student</a></li>
                    </ul>
                  </li>
                  <li><a href="addon-manager.html"><i class="fas fa-layer-group"></i>Addon</a></li>
                  <li><a href="themes.html"><i class="fas fa-paint-brush"></i>Themes</a></li>
                  <li><a href="messages.html"><i class="fas fa-comments"></i>Message</a></li>
                  <li><a href="my-profile.html"><i class="fas fa-address-card"></i>My Profile</a></li>
                  <li class="dropdown">
                    <a href="javascript:void(0);"><i class="fas fa-cog"></i>Settings<span class="ti-angle-left"></span></a>
                    <ul class="nav nav-second-level">
                      <li><a href="website-settings.html">Website Settings</a></li>
                      <li><a href="system-settings.html">System Settings</a></li>
                      <li><a href="payment_settings.html">Payment Settings</a></li>
                      <li><a href="social-login.html">Social Logins</a></li>
                      <li><a href="smtp-setting.html">SMTP Settings</a></li>
                      <li><a href="dash-about.html">About App</a></li>
                    </ul>
                  </li>
                </ul>
              </div>

            </div>
          </div>

          <div class="col-lg-9 col-md-9 col-sm-12">

            <!-- Row -->
            <div class="row justify-content-between">
              <div class="col-lg-12 col-md-12 col-sm-12 pb-4">
                <div class="dashboard_wrap d-flex align-items-center justify-content-between">
                  <div class="arion">
                    <nav class="transparent">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Coupon Code</li>
                      </ol>
                    </nav>
                  </div>
                  <div class="elkios">
                    <a href="#" class="add_new_btn" data-toggle="modal" data-target="#couponModal"><i class="fas fa-plus-circle mr-1"></i>Add Coupon Code</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Row -->

            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="dashboard_wrap">

                  <div class="row justify-content-between">
                    <div class="col-xl-2 col-lg-3 col-md-6">
                      <div class="form-group smalls row align-items-center">
                        <label class="col-xl-3 col-lg-3 col-sm-2 col-form-label">Show</label>
                        <div class="col-xl-9 col-lg-9 col-sm-10">
                          <select id="show" class="form-control">
                            <option value="">&nbsp;</option>
                            <option value="1">10</option>
                            <option value="2">25</option>
                            <option value="3">35</option>
                            <option value="3">50</option>
                            <option value="3">100</option>
                            <option value="3">250</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-5 col-md-6">
                      <div class="form-group smalls row align-items-center">
                        <label class="col-xl-2 col-lg-2 col-sm-2 col-form-label">Search</label>
                        <div class="col-xl-10 col-lg-10 col-sm-10">
                          <input type="text" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 mb-2">
                      <div class="table-responsive">
                        <table class="table dash_list">
                          <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Coupon Code</th>
                            <th scope="col">Discount Percentage</th>
                            <th scope="col">Validity</th>
                            <th scope="col">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>AB458AUDRT</td>
                            <td>50%</td>
                            <td>31-Jul-2021</td>
                            <td>
                              <div class="dropdown show">
                                <a class="btn btn-action" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="fas fa-ellipsis-h"></i>
                                </a>
                                <div class="drp-select dropdown-menu">
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Edit</a>
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Delete</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">1</th>
                            <td>AB458AUDRT</td>
                            <td>50%</td>
                            <td>31-Jul-2021</td>
                            <td>
                              <div class="dropdown show">
                                <a class="btn btn-action" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="fas fa-ellipsis-h"></i>
                                </a>
                                <div class="drp-select dropdown-menu">
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Edit</a>
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Delete</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">1</th>
                            <td>AB458AUDRT</td>
                            <td>50%</td>
                            <td>31-Jul-2021</td>
                            <td>
                              <div class="dropdown show">
                                <a class="btn btn-action" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="fas fa-ellipsis-h"></i>
                                </a>
                                <div class="drp-select dropdown-menu">
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Edit</a>
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Delete</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">1</th>
                            <td>AB458AUDRT</td>
                            <td>50%</td>
                            <td>31-Jul-2021</td>
                            <td>
                              <div class="dropdown show">
                                <a class="btn btn-action" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="fas fa-ellipsis-h"></i>
                                </a>
                                <div class="drp-select dropdown-menu">
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Edit</a>
                                  <a class="dropdown-item" href="JavaScript:Void(0);">Delete</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-between">
                    <div class="col-xl-6 col-lg-6 col-md-12">
                      <p class="p-0">Showing 1 to 15 of 15 entire</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12">
                      <nav class="float-right">
                        <ul class="pagination smalls m-0">
                          <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1"><i class="fas fa-arrow-circle-left"></i></a>
                          </li>
                          <li class="page-item"><a class="page-link" href="#">1</a></li>
                          <li class="page-item active">
                            <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                          </li>
                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                          <li class="page-item">
                            <a class="page-link" href="#"><i class="fas fa-arrow-circle-right"></i></a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Dashboard: Dashboard End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Modal -->
    <div class="modal" id="couponModal" tabindex="-1" role="dialog" aria-labelledby="couponModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Coupon Code Form</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <form class="forms_block">

              <div class="form-group smalls">
                <label>Coupon Code</label>
                <input type="text" class="form-control" placeholder="ABCRTY4587">
              </div>

              <div class="form-group smalls">
                <label>Discount Percentage</label>
                <input type="text" class="form-control" placeholder="20%">
              </div>

              <div class="form-group smalls">
                <label>Validity</label>
                <input type="text" class="form-control" placeholder="10/08/2022">
              </div>

              <div class="form-group smalls">
                <button type="button" class="btn full-width theme-bg text-white">Submit & Add</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "coupons"
}
</script>

<style scoped>

</style>
