<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">
    <!-- ============================ Signup Wrap ================================== -->
    <section>
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-xl-7 col-lg-8 col-md-12 col-sm-12">
            <form>
              <div class="crs_log_wrap">
                <div class="crs_log__thumb">
                  <img src="https://via.placeholder.com/1920x1200" class="img-fluid" alt="" />
                </div>
                <div class="crs_log__caption">
                  <div class="rcs_log_123">
                    <div class="rcs_ico"><i class="fas fa-user"></i></div>
                  </div>

                  <div class="rcs_log_124">
                    <div class="Lpo09"><h4>Login Your Account</h4></div>
                    <div class="form-group row mb-0">
                      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label>First Name</label>
                          <input type="text" class="form-control" placeholder="First Name" />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input type="text" class="form-control" placeholder="Last Name" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input type="text" class="form-control" placeholder="support@themezhub.com" />
                    </div>
                    <div class="form-group">
                      <label>Password</label>
                      <input type="text" class="form-control" placeholder="*******" />
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn full-width btn-md theme-bg text-white">Sign Up</button>
                    </div>
                  </div>
                  <div class="rcs_log_125">
                    <span>Or SignUp with Social Info</span>
                  </div>
                  <div class="rcs_log_126">
                    <ul class="social_log_45 row">
                      <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                      <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                      <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_log__footer d-flex justify-content-between">
                  <div class="fhg_45"><p class="musrt">Already have account? <a href="login.html" class="theme-cl">Login</a></p></div>
                  <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </section>
    <!-- ============================ Signup Wrap ================================== -->
    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "signup"
}
</script>

<style scoped>

</style>
