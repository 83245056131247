<template>

  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-light head-shadow">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12 w-auto text-white theme-bg">
                    <span class="embos_45"><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Page Title Start================================== -->
    <section class="page-title gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">

            <div class="breadcrumbs-wrap">
              <h1 class="breadcrumb-title">Willing Page</h1>
              <nav class="transparent">
                <ol class="breadcrumb p-0">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active theme-cl" aria-current="page">Checkout</li>
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Our Story Start ================================== -->
    <section class="gray-simple">

      <div class="container">

        <!-- row Start -->
        <div class="row submit-page mb-4">
          <div class="xl-12 col-lg-12 col-md-12">
            <div class="alert alert-success text-center mb-0" role="alert">
              Hi Dear, Have you already an account? <a href="#" data-toggle="collapse" data-target="#login-frm">Please Login</a>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12">
            <div id="login-frm" class="collapse mt-3">
              <div class="row">

                <div class="col-lg-5 col-md-4 col-sm-6">
                  <div class="form-group">
                    <div class="input-with-icon">
                      <input type="text" class="form-control" placeholder="Username">
                      <i class="ti-search"></i>
                    </div>
                  </div>
                </div>

                <div class="col-lg-5 col-md-4 col-sm-6">
                  <div class="form-group">
                    <div class="input-with-icon">
                      <input type="text" class="form-control" placeholder="*******">
                      <i class="ti-lock"></i>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-12">
                  <div class="form-group">
                    <button type="submit" class="btn theme-bg text-white btn-md full-width">Submit</button>
                  </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6">
                  <input id="a-1" class="checkbox-custom" name="a-1" type="checkbox">
                  <label for="a-1" class="checkbox-custom-label">Remember Me</label>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 mt-2">
                  <a href="#" class="theme-cl">Forget Password?</a>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- /row -->

        <!-- row Start -->
        <div class="row frm_submit_block">
          <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="submit-page mb-4">
              <!-- row -->
              <div class="row">

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <h3 class="ml-0">Billing Detail</h3>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>Name<i class="req">*</i></label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>Email<i class="req">*</i></label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label>Company Name</label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label>Country<i class="req">*</i></label>
                    <select id="country" class="form-control">
                      <option value="">&nbsp;</option>
                      <option value="1">United State</option>
                      <option value="2">United kingdom</option>
                      <option value="3">India</option>
                      <option value="4">Canada</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label>Street<i class="req">*</i></label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>Apartment</label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>Town/City<i class="req">*</i></label>
                    <select id="town" class="form-control">
                      <option value="">&nbsp;</option>
                      <option value="1">Punjab</option>
                      <option value="2">Chandigarh</option>
                      <option value="3">Allahabad</option>
                      <option value="4">Lucknow</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>State<i class="req">*</i></label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>Postcode/Zip<i class="req">*</i></label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>Phone<i class="req">*</i></label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label>Landline</label>
                    <input type="text" class="form-control">
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label>Additional Information</label>
                    <textarea class="form-control ht-50"></textarea>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <input id="a-2" class="checkbox-custom" name="a-2" type="checkbox">
                    <label for="a-2" class="checkbox-custom-label">Create An Account</label>
                  </div>
                </div>

              </div>
              <!--/row -->
            </div>

            <div class="panel-group pay_opy980" id="payaccordion">

              <!-- Pay By Paypal -->
              <div class="panel panel-default border">
                <div class="panel-heading" id="pay">
                  <h4 class="panel-title">
                    <a data-toggle="collapse" role="button" data-parent="#payaccordion" href="#payPal" aria-expanded="true"  aria-controls="payPal" class="">PayPal<img src="assets/img/paypal.png" class="img-fluid" alt=""></a>
                  </h4>
                </div>
                <div id="payPal" class="panel-collapse collapse show" aria-labelledby="pay" data-parent="#payaccordion">
                  <div class="panel-body">
                    <form>
                      <div class="form-group">
                        <label>PayPal Email</label>
                        <input type="text" class="form-control simple" placeholder="paypal@gmail.com">
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn theme-bg btm-md full-width">Pay 400.00 USD</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <!-- Pay By Strip -->
              <div class="panel panel-default border">
                <div class="panel-heading" id="stripes">
                  <h4 class="panel-title">
                    <a data-toggle="collapse" role="button" data-parent="#payaccordion" href="#stripePay" aria-expanded="false"  aria-controls="stripePay" class="">Stripe<img src="assets/img/strip.png" class="img-fluid" alt=""></a>
                  </h4>
                </div>
                <div id="stripePay" class="collapse" aria-labelledby="stripes" data-parent="#payaccordion">
                  <div class="panel-body">
                    <form>

                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Card Holder Name</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Card Number</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-5 col-md-5 col-sm-6">
                          <div class="form-group">
                            <label>Expire Month</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-5 col-md-5 col-sm-6">
                          <div class="form-group">
                            <label>Expire Year</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-12">
                          <div class="form-group">
                            <label>CVC</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <input id="ak-2" class="checkbox-custom" name="ak-2" type="checkbox">
                            <label for="ak-2" class="checkbox-custom-label">By Continuing, you ar'e agree to conditions</label>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group text-center">
                            <a href="#" class="btn theme-bg full-width">Pay 202.00 USD</a>
                          </div>
                        </div>

                      </div>

                    </form>
                  </div>
                </div>
              </div>

              <!-- Pay By Debit or credtit -->
              <div class="panel panel-default border">
                <div class="panel-heading" id="dabit">
                  <h4 class="panel-title">
                    <a data-toggle="collapse" href="#payaccordion" data-target="#debitPay" aria-expanded="false"  aria-controls="debitPay" class="">Debit Or Credit<img src="assets/img/debit.png" class="img-fluid" alt=""></a>
                  </h4>
                </div>
                <div id="debitPay" class="panel-collapse collapse" aria-labelledby="dabit" data-parent="#payaccordion">
                  <div class="panel-body">
                    <form>
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Card Holder Name</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Card Number</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-5 col-md-5 col-sm-6">
                          <div class="form-group">
                            <label>Expire Month</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-5 col-md-5 col-sm-6">
                          <div class="form-group">
                            <label>Expire Year</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-12">
                          <div class="form-group">
                            <label>CVC</label>
                            <input type="text" class="form-control">
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <input id="al-2" class="checkbox-custom" name="al-2" type="checkbox">
                            <label for="al-2" class="checkbox-custom-label">By Continuing, you ar'e agree to conditions</label>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group text-center">
                            <a href="#" class="btn theme-bg full-width">Pay 202.00 USD</a>
                          </div>
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- Col-lg 4 -->
          <div class="col-lg-4 col-md-12 col-sm-12">

            <div class="col-lg-12 col-md-12 col-sm-12">
              <h3>Your Order</h3>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="pro_product_wrap">
                <h5>Platinum</h5>
                <ul>
                  <li><strong>Total</strong>$319</li>
                  <li><strong>Subtotal</strong>$319</li>
                  <li><strong>Tax</strong>$10</li>
                  <li><strong>Total</strong>$329</li>
                </ul>
              </div>
            </div>

          </div>
          <!-- /col-lg-4 -->
        </div>
        <!-- /row -->

      </div>

    </section>
    <!-- ============================ Our Story End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "checkout"
}
</script>

<style scoped>

</style>
