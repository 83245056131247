<template v-loading="true">
  <top-header></top-header>
  <router-view></router-view>
  <a id="back2Top" class="top-scroll" title="回到顶部" href="#" ><i class="ti-arrow-up"></i></a>
  <bottom-footer></bottom-footer>
</template>
<script>
import TopHeader from "@/components/top-header";
import BottomFooter from "@/components/bottom-footer";
export default {
  name: 'App',
  components: {BottomFooter, TopHeader},
}
</script>

<style>
</style>
