<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-light head-shadow">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12 w-auto text-white theme-bg">
                    <span class="embos_45"><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Page Title Start================================== -->
    <div class="ed_detail_head">
      <div class="container">
        <div class="row align-items-center mb-5">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="authi_125">
              <div class="authi_125_thumb">
                <img src="https://via.placeholder.com/700x550" class="img-fluid rounded" alt="" />
              </div>
              <a href="#" class="klio_45"><div class="lklo_45"><i class="fas fa-play"></i></div><h6>Preview</h6></a>
            </div>
          </div>

          <div class="col-lg-9 col-md-12 col-sm-12">
            <div class="dlkio_452">
              <div class="ed_detail_wrap">
                <div class="crs_cates cl_1"><span>Design</span></div><div class="crs_cates cl_3"><span>Design</span></div>
                <div class="ed_header_caption">
                  <h2 class="ed_title">Ruby on Rails Program</h2>
                  <ul>
                    <li><i class="ti-calendar"></i>10 - 20 weeks</li>
                    <li><i class="ti-control-forward"></i>102 Lectures</li>
                    <li><i class="ti-user"></i>502 Student Enrolled</li>
                  </ul>
                </div>
                <div class="ed_header_short">
                  <p>Excepteur sint occaecat cupidatat non proident, deserunt mollit anim id est laborum. accusantium doloremque laudantium,vitae dicta <a href="#" class="theme-cl">Read More..</a>.</p>
                </div>

                <div class="ed_rate_info">
                  <div class="star_info">
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star"></i>
                  </div>
                  <div class="review_counter">
                    <strong class="high">4.7</strong> 3572 Reviews
                  </div>
                </div>

              </div>
              <div class="dlkio_last">
                <div class="ed_view_link">
                  <a href="#" class="btn theme-bg enroll-btn">Get Membership<i class="ti-angle-right"></i></a>
                  <a href="#" class="btn theme-border enroll-btn">Share<i class="fas fa-share"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ================================ Tag Award ================================ -->
    <section class="p-0 gray">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="crp_box fl_color ovr_top">
              <div class="row align-items-center">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141"><i class="fa fa-calendar"></i></div>
                    <div class="dro_142">
                      <h6>Starts on Aug 21</h6>
                      <p>Aug 21, 2021 - Oct 21, 2021</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141 st-1"><i class="fa fa-play"></i></div>
                    <div class="dro_142">
                      <h6>Lessions</h6>
                      <p>35 Lectures</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141 st-3"><i class="fa fa-user-shield"></i></div>
                    <div class="dro_142">
                      <h6>Current Students</h6>
                      <p>22k Students Enrolled</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- ================================ Tag Award ================================ -->

    <!-- ============================ Course Detail ================================== -->
    <section class="gray">
      <div class="container">
        <div class="row">

          <div class="col-lg-8 col-md-12 order-lg-first">
            <!-- Overview -->
            <div class="liop_wraps">
              <div class="liop_wraps_01">
                <h6>Week 01</h6>
                <span>Aug 9 - 15 • 04 Lectures</span>
              </div>
              <div class="liop_wraps_list">
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>10</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 1 . 10:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>12</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 2 . 12:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>14</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 3 . 08:00 AM</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Overview -->
            <div class="liop_wraps">
              <div class="liop_wraps_01">
                <h6>Week 02</h6>
                <span>Aug 9 - 15 • 04 Lectures</span>
              </div>
              <div class="liop_wraps_list">
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>10</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 1 . 10:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>12</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 2 . 12:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>14</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 3 . 08:00 AM</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Overview -->
            <div class="liop_wraps">
              <div class="liop_wraps_01">
                <h6>Week 03</h6>
                <span>Aug 9 - 15 • 04 Lectures</span>
              </div>
              <div class="liop_wraps_list">
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>10</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 1 . 10:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>12</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 2 . 12:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>14</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 3 . 08:00 AM</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Overview -->
            <div class="liop_wraps">
              <div class="liop_wraps_01">
                <h6>Week 04</h6>
                <span>Aug 9 - 15 • 04 Lectures</span>
              </div>
              <div class="liop_wraps_list">
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>10</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 1 . 10:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>12</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 2 . 12:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>14</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 3 . 08:00 AM</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Overview -->
            <div class="liop_wraps">
              <div class="liop_wraps_01">
                <h6>Week 05</h6>
                <span>Aug 9 - 15 • 04 Lectures</span>
              </div>
              <div class="liop_wraps_list">
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>10</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 1 . 10:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>12</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 2 . 12:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>14</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 3 . 08:00 AM</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Overview -->
            <div class="liop_wraps">
              <div class="liop_wraps_01">
                <h6>Week 06</h6>
                <span>Aug 9 - 15 • 04 Lectures</span>
              </div>
              <div class="liop_wraps_list">
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>10</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 1 . 10:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>12</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 2 . 12:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>14</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 3 . 08:00 AM</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Overview -->
            <div class="liop_wraps">
              <div class="liop_wraps_01">
                <h6>Week 07</h6>
                <span>Aug 9 - 15 • 04 Lectures</span>
              </div>
              <div class="liop_wraps_list">
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>10</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 1 . 10:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>12</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 2 . 12:00 AM</span>
                  </div>
                </div>
                <!-- single List -->
                <div class="liop_wraps_single">
                  <div class="lki_813"><h6>Aug</h6><span>14</span></div>
                  <div class="bhu_486">
                    <h5>Introduction to Indian Administration, Kautilya's Arthshastra</h5>
                    <span>Lessions 3 . 08:00 AM</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- Sidebar -->
          <div class="col-lg-4 col-md-12 order-lg-last">

            <!-- Course info -->
            <div class="ed_view_box style_3 stick_top">

              <div class="property_video sm">
                <div class="thumb">
                  <img class="pro_img img-fluid w100" src="https://via.placeholder.com/1920x1200" alt="7.jpg">
                  <div class="overlay_icon">
                    <div class="bb-video-box">
                      <div class="bb-video-box-inner">
                        <div class="bb-video-box-innerup">
                          <a href="https://www.youtube.com/watch?v=A8EI6JaFbv4" data-toggle="modal" data-target="#popup-video" class="theme-cl"><i class="ti-control-play"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ed_view_price pl-4">
                <span>Acctual Price</span>
                <h2 class="theme-cl">$ 149.00</h2>
              </div>

              <div class="ed_view_short pl-4 pr-4 pb-2">
                <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
              </div>

              <div class="ed_view_features half_list pl-4 pr-3">
                <span>Course Features</span>
                <ul>
                  <li><i class="ti-user"></i>3k Students View</li>
                  <li><i class="ti-time"></i>2 hour 30 min</li>
                  <li><i class="ti-bar-chart-alt"></i>Principiante</li>
                  <li><i class="ti-cup"></i>04 Certified</li>
                </ul>
              </div>
              <div class="ed_view_link">
                <a href="#" class="btn theme-light enroll-btn">Get Membership<i class="ti-angle-right"></i></a>
                <a href="#" class="btn theme-bg enroll-btn">Enroll Now<i class="ti-angle-right"></i></a>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
    <!-- ============================ Course Detail ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "course-detail-3"
}
</script>

<style scoped>

</style>
