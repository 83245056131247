<template>

  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-light head-shadow">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12 w-auto text-white theme-bg">
                    <span class="embos_45"><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Page Title Start================================== -->
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">

            <div class="breadcrumbs-wrap">
              <h1 class="breadcrumb-title">Let' Start New Design in Adobe Photoshop</h1>
              <nav class="transparent">
                <ol class="breadcrumb p-0">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active theme-cl" aria-current="page">Blog Detail</li>
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Blog Detail Start ================================== -->
    <section class="gray">

      <div class="container">

        <!-- row Start -->
        <div class="row">

          <!-- Blog Detail -->
          <div class="col-lg-8 col-md-12 col-sm-12 col-12">
            <div class="article_detail_wrapss single_article_wrap format-standard">
              <div class="article_body_wrap">

                <div class="article_featured_image">
                  <img class="img-fluid" src="https://via.placeholder.com/1200x800" alt="">
                </div>

                <div class="article_top_info">
                  <ul class="article_middle_info">
                    <li><a href="#"><span class="icons"><i class="ti-user"></i></span>by Rosalina Doe</a></li>
                    <li><a href="#"><span class="icons"><i class="ti-comment-alt"></i></span>45 Comments</a></li>
                  </ul>
                </div>
                <h2 class="post-title">Lorem ipsum dolor sit amet, cons pisicing elit, sed do.</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem. <br><br> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>
                <blockquote>
                  <span class="icon"><i class="fas fa-quote-left"></i></span>
                  <p class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tem
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ullamco laboris nisi ut aliquip ex ea commodo onsequat.</p>
                  <h5 class="name">- Rosalina Pong</h5>
                </blockquote>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                  dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
                  inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                  ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
                  magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
                  dolorem. <br><br>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis
                  iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                  eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>
              </div>
            </div>

            <!-- Author Detail -->
            <div class="article_detail_wrapss single_article_wrap format-standard">

              <div class="article_posts_thumb">
                <span class="img"><img class="img-fluid" src="https://via.placeholder.com/500x500" alt=""></span>
                <h3 class="pa-name">Rosalina William</h3>
                <ul class="social-links">
                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fab fa-behance"></i></a></li>
                  <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                  <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                </ul>
                <p class="pa-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
              </div>

            </div>

            <!-- Blog Comment -->
            <div class="article_detail_wrapss single_article_wrap format-standard">

              <div class="comment-area">
                <div class="all-comments">
                  <h3 class="comments-title">05 Comments</h3>
                  <div class="comment-list">
                    <ul>
                      <li class="article_comments_wrap">
                        <article>
                          <div class="article_comments_thumb">
                            <img src="https://via.placeholder.com/500x500" alt="">
                          </div>
                          <div class="comment-details">
                            <div class="comment-meta">
                              <div class="comment-left-meta">
                                <h4 class="author-name">Rosalina Kelian <span class="selected"><i class="fas fa-bookmark"></i></span></h4>
                                <div class="comment-date">19th May 2018</div>
                              </div>
                              <div class="comment-reply">
                                <a href="#" class="reply"><span class="icona"><i class="ti-back-left"></i></span> Reply</a>
                              </div>
                            </div>
                            <div class="comment-text">
                              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim laborumab.
                                perspiciatis unde omnis iste natus error.</p>
                            </div>
                          </div>
                        </article>
                        <ul class="children">
                          <li class="article_comments_wrap">
                            <article>
                              <div class="article_comments_thumb">
                                <img src="https://via.placeholder.com/500x500" alt="">
                              </div>
                              <div class="comment-details">
                                <div class="comment-meta">
                                  <div class="comment-left-meta">
                                    <h4 class="author-name">Rosalina Kelian</h4>
                                    <div class="comment-date">19th May 2018</div>
                                  </div>
                                  <div class="comment-reply">
                                    <a href="#" class="reply"><span class="icons"><i class="ti-back-left"></i></span> Reply</a>
                                  </div>
                                </div>
                                <div class="comment-text">
                                  <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim laborumab.
                                    perspiciatis unde omnis iste natus error.</p>
                                </div>
                              </div>
                            </article>
                            <ul class="children">
                              <li class="article_comments_wrap">
                                <article>
                                  <div class="article_comments_thumb">
                                    <img src="https://via.placeholder.com/500x500" alt="">
                                  </div>
                                  <div class="comment-details">
                                    <div class="comment-meta">
                                      <div class="comment-left-meta">
                                        <h4 class="author-name">Rosalina Kelian</h4>
                                        <div class="comment-date">19th May 2018</div>
                                      </div>
                                      <div class="comment-reply">
                                        <a href="#" class="reply"><span class="icons"><i class="ti-back-left"></i></span> Reply</a>
                                      </div>
                                    </div>
                                    <div class="comment-text">
                                      <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim laborumab.
                                        perspiciatis unde omnis iste natus error.</p>
                                    </div>
                                  </div>
                                </article>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li class="article_comments_wrap">
                        <article>
                          <div class="article_comments_thumb">
                            <img src="https://via.placeholder.com/500x500" alt="">
                          </div>
                          <div class="comment-details">
                            <div class="comment-meta">
                              <div class="comment-left-meta">
                                <h4 class="author-name">Rosalina Kelian</h4>
                                <div class="comment-date">19th May 2018</div>
                              </div>
                              <div class="comment-reply">
                                <a href="#" class="reply"><span class="icons"><i class="ti-back-left"></i></span> Reply</a>
                              </div>
                            </div>
                            <div class="comment-text">
                              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim laborumab.
                                perspiciatis unde omnis iste natus error.</p>
                            </div>
                          </div>
                        </article>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="comment-box submit-form">
                  <h3 class="reply-title">Post Comment</h3>
                  <div class="comment-form">
                    <form action="#">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name">
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Email">
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <textarea name="comment" class="form-control" cols="30" rows="6" placeholder="Type your comments...."></textarea>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <a href="#" class="btn theme-bg text-white">Submit Now</a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>


          </div>

          <!-- Single blog Grid -->
          <div class="col-lg-4 col-md-12 col-sm-12 col-12">

            <!-- Searchbard -->
            <div class="single_widgets widget_search">
              <h4 class="title">Search</h4>
              <form action="#" class="sidebar-search-form">
                <input type="search" name="search" placeholder="Search..">
                <button type="submit"><i class="ti-search"></i></button>
              </form>
            </div>

            <!-- Categories -->
            <div class="single_widgets widget_category">
              <h4 class="title">Categories</h4>
              <ul>
                <li><a href="#">Lifestyle <span>09</span></a></li>
                <li><a href="#">Travel <span>12</span></a></li>
                <li><a href="#">Fashion <span>19</span></a>
                </li><li><a href="#">Branding <span>17</span></a></li>
                <li><a href="#">Music <span>10</span></a></li>
              </ul>
            </div>

            <!-- Trending Posts -->
            <div class="single_widgets widget_thumb_post">
              <h4 class="title">Trending Posts</h4>
              <ul>
                <li>
										<span class="left">
											<img src="https://via.placeholder.com/1200x800" alt="" class="">
										</span>
                  <span class="right">
											<a class="feed-title" href="#">Alonso Kelina Falao Asiano Pero</a>
											<span class="post-date"><i class="ti-calendar"></i>10 Min ago</span>
										</span>
                </li>
                <li>
										<span class="left">
											<img src="https://via.placeholder.com/1200x800" alt="" class="">
										</span>
                  <span class="right">
											<a class="feed-title" href="#">It is a long established fact that a reader</a>
											<span class="post-date"><i class="ti-calendar"></i>2 Hours ago</span>
										</span>
                </li>
                <li>
										<span class="left">
											<img src="https://via.placeholder.com/1200x800" alt="" class="">
										</span>
                  <span class="right">
											<a class="feed-title" href="#">Many desktop publish packages and web</a>
											<span class="post-date"><i class="ti-calendar"></i>4 Hours ago</span>
										</span>
                </li>
                <li>
										<span class="left">
											<img src="https://via.placeholder.com/1200x800" alt="" class="">
										</span>
                  <span class="right">
											<a class="feed-title" href="#">Various versions have evolved over the years</a>
											<span class="post-date"><i class="ti-calendar"></i>7 Hours ago</span>
										</span>
                </li>
                <li>
										<span class="left">
											<img src="https://via.placeholder.com/1200x800" alt="" class="">
										</span>
                  <span class="right">
											<a class="feed-title" href="#">Photo booth anim 8-bit PBR 3 wolf moon.</a>
											<span class="post-date"><i class="ti-calendar"></i>3 Days ago</span>
										</span>
                </li>
              </ul>
            </div>

            <!-- Tags Cloud -->
            <div class="single_widgets widget_tags">
              <h4 class="title">Tags Cloud</h4>
              <ul>
                <li><a href="#">Lifestyle</a></li>
                <li><a href="#">Travel</a></li>
                <li><a href="#">Fashion</a></li>
                <li><a href="#">Branding</a></li>
                <li><a href="#">Music</a></li>
              </ul>
            </div>

          </div>

        </div>
        <!-- /row -->

      </div>

    </section>
    <!-- ============================ Blog Detail End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "blog-detail"
}
</script>

<style scoped>

</style>
