<template>
  <div class="yellow-skin">

  <!-- ============================================================== -->
  <!-- Preloader - style you can find in spinners.css -->
  <!-- ============================================================== -->
  <div class="preloader"></div>

  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-light head-shadow">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li class="_my_prt_list"><a href="#"><span>2</span>My List</a></li>
                <li><a href="#" data-toggle="modal" data-target="#login"><i class="fas fa-user-circle fa-lg"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper" style="transition-property: none;">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                  <li><a href="map.html">Map Home</a></li>
                </ul>
              </li>

              <li><a href="#">Listings<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Listing Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Style 1</a></li>
                      <li><a href="grid-layout-2.html">Grid Style 2</a></li>
                      <li><a href="grid-layout-3.html">Grid Style 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Listing List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Style 1</a></li>
                      <li><a href="list-layout-with-map-2.html">List Style 2</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Listing Map<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="half-map.html">Half Map</a></li>
                      <li><a href="half-map-2.html">Half Map 2</a></li>
                      <li><a href="classical-layout-with-map.html">Classical With Sidebar</a></li>
                      <li><a href="list-layout-with-map.html">List Sidebar Map</a></li>
                      <li><a href="grid-layout-with-map.html">Grid Sidebar Map</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Agents View<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="agents.html">Agent Grid Style</a></li>
                      <li><a href="agents-2.html">Agent Grid 2</a></li>
                      <li><a href="agent-page.html">Agent Detail Page</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Agency View<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="agencies.html">Agency Grid Style</a></li>
                      <li><a href="agency-page.html">Agency Detail Page</a></li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li><a href="#">Property<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li class=""><a href="#">User Admin<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="dashboard.html">User Dashboard</a></li>
                      <li><a href="my-profile.html">My Profile</a></li>
                      <li><a href="my-property.html">My Property</a></li>
                      <li><a href="messages.html">Messages</a></li>
                      <li><a href="bookmark-list.html">Bookmark Property</a></li>
                      <li><a href="submit-property.html">Submit Property</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Single Property<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="single-property-1.html">Single Property 1</a></li>
                      <li><a href="single-property-2.html">Single Property 2</a></li>
                      <li><a href="single-property-3.html">Single Property 3</a></li>
                      <li><a href="single-property-4.html">Single Property 4</a></li>
                    </ul>
                  </li>
                  <li><a href="compare-property.html">Compare Property</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="blog.html">Blog Style</a></li>
                  <li><a href="about-us.html">About Us</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="checkout.html">Checkout</a></li>
                  <li><a href="contact.html">Contact</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="privacy.html">Privacy Policy</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                </ul>
              </li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-user-circle mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="submit-property.html"  class="theme-cl">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Page Title Start================================== -->
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">

            <div class="breadcrumbs-wrap">
              <h1 class="breadcrumb-title">Courses with Sidebar</h1>
              <nav class="transparent">
                <ol class="breadcrumb p-0 bg-white">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active theme-cl" aria-current="page">Product Wishlist</li>
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Product Wishlist ================================== -->
    <section class="pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12">
            <div class="table-responsive">
              <table class="table table-striped wishlist">
                <tbody>
                <tr>
                  <td><a href="#" class="remove_cart"><i class="ti-close"></i></a></td>
                  <td><div class="tb_course_thumb"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt="" /></div></td>
                  <th>The Computer Science of Human Decisions<span class="tb_date">18 july 2020</span></th>
                  <td><span class="wish_price theme-cl">$149.00</span></td>
                  <td>In Stock</td>
                  <td><a href="#" class="btn theme-bg text-white">Add To Cart</a></td>
                </tr>
                <tr>
                  <td><a href="#" class="remove_cart"><i class="ti-close"></i></a></td>
                  <td><div class="tb_course_thumb"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt="" /></div></td>
                  <th>The Computer Science of Human Decisions<span class="tb_date">15 july 2020</span></th>
                  <td><span class="wish_price theme-cl">$129.00</span></td>
                  <td>In Stock</td>
                  <td><a href="#" class="btn theme-bg text-white">Add To Cart</a></td>
                </tr>
                <tr>
                  <td><a href="#" class="remove_cart"><i class="ti-close"></i></a></td>
                  <td><div class="tb_course_thumb"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt="" /></div></td>
                  <th>The Computer Science of Human Decisions<span class="tb_date">13 july 2020</span></th>
                  <td><span class="wish_price theme-cl">$125.00</span></td>
                  <td>In Stock</td>
                  <td><a href="#" class="btn theme-bg text-white">Add To Cart</a></td>
                </tr>
                <tr>
                  <td><a href="#" class="remove_cart"><i class="ti-close"></i></a></td>
                  <td><div class="tb_course_thumb"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt="" /></div></td>
                  <th>The Computer Science of Human Decisions<span class="tb_date">12 july 2020</span></th>
                  <td><span class="wish_price theme-cl">$179.00</span></td>
                  <td>In Stock</td>
                  <td><a href="#" class="btn theme-bg text-white">Add To Cart</a></td>
                </tr>
                <tr>
                  <td><a href="#" class="remove_cart"><i class="ti-close"></i></a></td>
                  <td><div class="tb_course_thumb"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt="" /></div></td>
                  <th>The Computer Science of Human Decisions<span class="tb_date">11 july 2020</span></th>
                  <td><span class="wish_price theme-cl">$180.00</span></td>
                  <td>In Stock</td>
                  <td><a href="#" class="btn theme-bg text-white">Add To Cart</a></td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Product Wishlist ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->
  </div>
</template>

<script>
export default {
  name: "wishlist"
}
</script>

<style scoped>

</style>
