<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-transparent dark-text">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12 w-auto text-white theme-bg">
                    <span class="embos_45"><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Hero Banner  Start================================== -->
    <div class="hero_banner image-cover image_bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-7 col-sm-12">
            <div class="simple-search-wrap text-left">
              <div class="hero_search-2">
                <h1 class="banner_title mb-4">Find the <span class="theme-cl">most exciting</span> online cources</h1>
                <p class="font-lg mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                <div class="inline_btn mt-5">
                  <a href="#" class="btn theme-bg btn-md text-white">Get Started</a>
                  <a href="#" class="btn text-dark pl-sm-0"><span class="esli_vd"><i class="fa fa-play"></i></span>How It Works?</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-5 col-sm-12">
            <div class="side_block">
              <img src="assets/img/h-5.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

    <!-- ============================ How It Works Start ================================== -->
    <section class="min gray">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>How It <span class="theme-cl">Works?</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-5">

          <!-- Single Location -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="wrk_grid">
              <div class="wrk_grid_ico">
                <i class="fa fa-search-location"></i>
              </div>
              <div class="wrk_caption">
                <h4>Find Cources</h4>
                <p>We have helped over 3,400 new students to get into the most popular tech teams.</p>
              </div>
            </div>
          </div>

          <!-- Single Location -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="wrk_grid active">
              <div class="wrk_grid_ico">
                <i class="fa fa-calendar-week"></i>
              </div>
              <div class="wrk_caption">
                <h4>Book Your Seat</h4>
                <p>We have helped over 3,400 new students to get into the most popular tech teams.</p>
              </div>
            </div>
          </div>

          <!-- Single Location -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="wrk_grid">
              <div class="wrk_grid_ico">
                <i class="fa fa-award"></i>
              </div>
              <div class="wrk_caption">
                <h4>Get Certificate</h4>
                <p>We have helped over 3,400 new students to get into the most popular tech teams.</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ How It Works End ================================== -->

    <!-- ============================ Cources Start ================================== -->
    <section>
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Featured Cources <span class="theme-cl">For You</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Development</span>
                  <span class="est st_2">PHP</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">Advance PHP knowledge with laravel to make smart web</h4>
                  <span class="crs_auth"><i>By</i> Adam Wilson</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">99</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Insurence</span>
                  <span class="est st_2">Banking</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">The complete accounting & bank financial course 2021</h4>
                  <span class="crs_auth"><i>By</i> Mike Hussey</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">139</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Software</span>
                  <span class="est st_2">Technology</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">The complete business plan course includes 50 templates</h4>
                  <span class="crs_auth"><i>By</i> Litha Joshi</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">77.99</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Business</span>
                  <span class="est st_2">Finance</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">Full web designing course with 20 web template designing</h4>
                  <span class="crs_auth"><i>By</i> Adam Wilson</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">129</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8 mt-2">
            <div class="text-center"><a href="grid-layout-with-sidebar.html" class="btn btn-md theme-bg-light theme-cl">Explore More Cources</a></div>
          </div>
        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Cources End ================================== -->

    <!-- ============================ Work Process Start ================================== -->
    <section class="gray">
      <div class="container">

        <div class="row align-items-center justify-content-between mb-5">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="lmp_caption">
              <h2 class="mb-3">We Have The Best Instructors Available in The City</h2>
              <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique</p>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Full lifetime access</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">20+ downloadable resources</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Certificate of completion</h6>
                </div>
              </div>
              <div class="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div class="d-flex align-items-center">
                  <div class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                    <i class="fas fa-check"></i>
                  </div>
                  <h6 class="mb-0 ml-3">Free Trial 7 Days</h6>
                </div>
              </div>
              <div class="text-left mt-4"><a href="#" class="btn btn-md text-light theme-bg">Enrolled Today</a></div>
            </div>
          </div>

          <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <div class="side_block extream_img">
              <div class="list_crs_img">
                <img src="assets/img/img-1.png" class="img-fluid elsio cirl animate-fl-y" alt="" />
                <img src="assets/img/img-3.png" class="img-fluid elsio arrow animate-fl-x" alt="" />
                <img src="assets/img/img-2.png" class="img-fluid elsio moon animate-fl-x" alt="" />
              </div>
              <img src="assets/img/st-3.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pt-0 gray">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="side_block extream_img">
              <div class="list_crs_img">
                <img src="assets/img/at-1.png" class="img-fluid elsio cirl animate-fl-y" alt="" />
                <img src="assets/img/at-3.png" class="img-fluid elsio arrow animate-fl-x" alt="" />
                <img src="assets/img/at-2.png" class="img-fluid elsio moon animate-fl-x" alt="" />
              </div>
              <img src="assets/img/st-2.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <div class="lmp_caption">
              <ol class="list-unstyled p-0">
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center theme-bg">
                    <div class="position-absolute text-white h5 mb-0">1</div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>Create account</h4>
                    <p>
                      Oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center theme-bg">
                    <div class="position-absolute text-white h5 mb-0">3</div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>Join Membership</h4>
                    <p>
                      Error sit voluptatem actium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center theme-bg">
                    <div class="position-absolute text-white h5 mb-0">3</div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>Start Learning</h4>
                    <p>
                      Error sit voluptatem actium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
                <li class="d-flex align-items-start my-3 my-md-4">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center theme-bg">
                    <div class="position-absolute text-white h5 mb-0">4</div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>Get Certificate</h4>
                    <p>
                      Unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Work Process End ================================== -->

    <!-- ============================ Pricing Table ================================== -->
    <section class="min">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Select Your <span class="theme-cl">Package</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row align-items-center">

          <!-- Single Package -->
          <div class="col-lg-4 col-md-4">
            <div class="pricing_wrap">
              <div class="prt_head">
                <h4>Basic</h4>
              </div>
              <div class="prt_price">
                <h2><span>$</span>29</h2>
                <span>per user, per month</span>
              </div>
              <div class="prt_body">
                <ul>
                  <li>99.5% Uptime Guarantee</li>
                  <li>120GB CDN Bandwidth</li>
                  <li>5GB Cloud Storage</li>
                  <li class="none">Personal Help Support</li>
                  <li class="none">Enterprise SLA</li>
                </ul>
              </div>
              <div class="prt_footer">
                <a href="#" class="btn choose_package">Start Basic</a>
              </div>
            </div>
          </div>

          <!-- Single Package -->
          <div class="col-lg-4 col-md-4">
            <div class="pricing_wrap">
              <div class="prt_head">
                <div class="recommended">Best Value</div>
                <h4>Standard</h4>
              </div>
              <div class="prt_price">
                <h2><span>$</span>49</h2>
                <span>per user, per month</span>
              </div>
              <div class="prt_body">
                <ul>
                  <li>99.5% Uptime Guarantee</li>
                  <li>150GB CDN Bandwidth</li>
                  <li>10GB Cloud Storage</li>
                  <li>Personal Help Support</li>
                  <li class="none">Enterprise SLA</li>
                </ul>
              </div>
              <div class="prt_footer">
                <a href="#" class="btn choose_package active">Start Standard</a>
              </div>
            </div>
          </div>

          <!-- Single Package -->
          <div class="col-lg-4 col-md-4">
            <div class="pricing_wrap">
              <div class="prt_head">
                <h4>Platinum</h4>
              </div>
              <div class="prt_price">
                <h2><span>$</span>79</h2>
                <span>2 user, per month</span>
              </div>
              <div class="prt_body">
                <ul>
                  <li>100% Uptime Guarantee</li>
                  <li>200GB CDN Bandwidth</li>
                  <li>20GB Cloud Storage</li>
                  <li>Personal Help Support</li>
                  <li>Enterprise SLA</li>
                </ul>
              </div>
              <div class="prt_footer">
                <a href="#" class="btn choose_package">Start Platinum</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <!-- ============================ Pricing Table End ================================== -->

    <!-- ============================ partner Start ================================== -->
    <section class="gray">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Trusted By <span class="theme-cl">10,000</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <!-- Single Item -->
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
            <div class="crs_partn">
              <div class="p-3">
                <img src="https://via.placeholder.com/400x110" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ partner End ================================== -->

    <!-- ============================ FAQ Start ================================== -->
    <section>
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Frequently Asked <span class="theme-cl">Questions</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div id="accordionExample" class="accordion">

              <!-- Part 1 -->
              <div class="card">
                <div id="headingOne" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" class="d-block position-relative text-dark collapsible-link py-2">How To Learn Web Designing Step by Step</a></h6>
                </div>
                <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" class="collapse show">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

              <!-- Part 2 -->
              <div class="card">
                <div id="headingTwo" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" class="d-block position-relative collapsed text-dark collapsible-link py-2">Learn Web Designing in Basic Level</a></h6>
                </div>
                <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample" class="collapse">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

              <!-- Part 3 -->
              <div class="card">
                <div id="headingThree" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" class="d-block position-relative collapsed text-dark collapsible-link py-2">Learn Web Designing in Advance Level</a></h6>
                </div>
                <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample" class="collapse">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

              <!-- Part 04 -->
              <div class="card">
                <div id="headingFour" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" class="d-block position-relative collapsed text-dark collapsible-link py-2">How To Become Succes in Designing & Development?</a></h6>
                </div>
                <div id="collapseFour" aria-labelledby="headingFour" data-parent="#accordionExample" class="collapse">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ FAQ End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="bg-cover" style="background:#f7f8f9 url(assets/img/call-bg.png)no-repeat;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-7 col-lg-8 col-md-12 col-sm-12">
            <div class="call_action mt-4 mb-4 text-center">
              <h2 class="mb-4">Get online counseling free!</h2>
              <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
              <a href="#" class="btn text-white theme-bg btn-md rounded">Enrolled Today</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="skin-light-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control brd" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom br_light">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 Rhoomy. Designd By <a href="http://www.bootstrapmb.com" class="theme-cl">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "home-5"
}
</script>

<style scoped>

</style>
