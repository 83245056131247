<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper" v-if="content">

    <!--    region 正副标题-->
    <div class="hero_banner image-cover image_bottom"
         style="height: 600px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-10 col-sm-12">
            <div class="simple-search-wrap">
              <div class="hero_search-2 text-center">
                <div class="elsio_tag">{{ content.title }}</div>
                <h1 class="banner_title mb-4">{{ content.subTitle }}</h1>
                <!--                <p class="font-lg mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>-->
                <!--                <div class="input-group simple_search">-->
                <!--                  <i class="fa fa-search ico"></i>-->
                <!--                  <input type="text" class="form-control" placeholder="Search Your Cources">-->
                <!--                  <div class="input-group-append">-->
                <!--                    <button class="btn theme-bg" type="button">Search</button>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    endregion 正副标题-->

    <!--    region 特色服务-->
    <template v-for="(item, index) in content.features" :key="'feature_'+index">
      <section :class="index%2!==0 ? 'gray' : ''">
        <div class="container">
          <div class="row align-items-center justify-content-between mb-5">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div class="lmp_caption">
                <h2 class="mb-3">{{ item.title }}</h2>
                <p>{{ item.description }}</p>
                <div class="mb-3 mr-4 ml-lg-0 mr-lg-4" v-for="(li, liInd) in  item.li" :key="'li_'+liInd">
                  <div class="d-flex align-items-center">
                    <div
                        class="rounded-circle bg-light-success theme-cl p-2 small d-flex align-items-center justify-content-center">
                      <i class="fas fa-check"></i>
                    </div>
                    <h6 class="mb-0 ml-3">{{ li }}</h6>
                  </div>
                </div>
                <div class="text-left mt-4"><a href="#" class="btn btn-md text-light theme-bg">{{ item.btnTitle }}</a>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div class="lmp_thumb">
<!--                <img src="https://via.placeholder.com/1000x850" class="img-fluid" alt=""/>-->
                <img v-if="index===0" src="@/assets/img/pic1.png" class="img-fluid" alt=""/>
                <img v-if="index===1" src="@/assets/img/pic2.png" class="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>

    </template>
    <!--    endregion 特色服务-->

    <!--    region 步骤-->
    <section v-if="content && content.usingSteps" class="pt-0">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="lmp_thumb">
<!--              <img src="https://via.placeholder.com/1000x850" class="img-fluid" alt=""/>-->
              <img src="@/assets/img/pic3.png" class="img-fluid" alt=""/>
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <div class="lmp_caption">
              <ol class="list-unstyled p-0">
                <li class="d-flex align-items-start my-3 my-md-4" v-for="(item, index) in content.usingSteps"
                    :key="'uStep_'+index">
                  <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center theme-bg">
                    <div class="position-absolute text-white h5 mb-0">{{ index + 1 }}</div>
                  </div>
                  <div class="ml-3 ml-md-4">
                    <h4>{{ item.title }}</h4>
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!--    endregion 步骤-->

    <div class="clearfix"></div>

    <!-- region 最受欢迎模板 -->
    <section class="gray" v-if="content && content.popularTemplates">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2><span class="theme-cl">最受欢迎</span> 模板</h2>
              <!--              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et-->
              <!--                dolore magna aliqua. Ut enim ad minim veniam.</p>-->
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 col-sm-12">

            <div class="reviews-slide space">

              <!-- Single Item -->
              <div class="single_items lios_item" v-for="(item, index) in content.popularTemplates"
                   :key="'popTmp_'+index">
                <div class="_testimonial_wrios shadow_none">
                  <div class="_testimonial_flex">
                    <div class="_testimonial_flex_first">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/500x500" class="img-fluid" alt="">
                      </div>
                      <div class="_tsl_flex_capst">
                        <h5>{{ item.name }}</h5>
                        <div class="_ovr_posts"><span>{{ item.serviceType }}</span></div>
                        <div class="_ovr_rates"><span><i class="fa fa-fire"></i></span>{{ formatter(item.userCount) }}
                        </div>
                      </div>
                    </div>
                    <div class="_testimonial_flex_first_last">
                      <div class="_tsl_flex_thumb">
                        <img src="https://via.placeholder.com/300x300" class="img-fluid" alt="">
                      </div>
                    </div>
                  </div>

                  <div class="facts-detail">
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </section>
    <!-- endregion 最受欢迎模板 -->

    <!--    region 心动不如行动-->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>心动了吗？</h3>
                <span>不如行动。</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">立即体验</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!--    endregion 心动不如行动-->

    <!--region 登录-->
<!--    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">-->
<!--      <div class="modal-dialog modal-xl login-pop-form" role="document">-->
<!--        <div class="modal-content overli" id="loginmodal">-->
<!--          <div class="modal-header">-->
<!--            <h5 class="modal-title">Login Your Account</h5>-->
<!--            <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="modal-body">-->
<!--            <div class="login-form">-->
<!--              <form>-->

<!--                <div class="form-group">-->
<!--                  <label>User Name</label>-->
<!--                  <div class="input-with-icon">-->
<!--                    <input type="text" class="form-control" placeholder="User or email">-->
<!--                    <i class="ti-user"></i>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="form-group">-->
<!--                  <label>Password</label>-->
<!--                  <div class="input-with-icon">-->
<!--                    <input type="password" class="form-control" placeholder="*******">-->
<!--                    <i class="ti-unlock"></i>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="form-group row">-->
<!--                  <div class="col-xl-4 col-lg-4 col-4">-->
<!--                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">-->
<!--                    <label for="admin" class="checkbox-custom-label">Admin</label>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-4">-->
<!--                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>-->
<!--                    <label for="student" class="checkbox-custom-label">Student</label>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-4">-->
<!--                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">-->
<!--                    <label for="instructor" class="checkbox-custom-label">Tutors</label>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="form-group">-->
<!--                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>-->
<!--                </div>-->

<!--                <div class="rcs_log_125 pt-2 pb-3">-->
<!--                  <span>Or Login with Social Info</span>-->
<!--                </div>-->
<!--                <div class="rcs_log_126 full">-->
<!--                  <ul class="social_log_45 row">-->
<!--                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i-->
<!--                        class="ti-facebook text-info"></i>Facebook</a></li>-->
<!--                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i-->
<!--                        class="ti-google text-danger"></i>Google</a></li>-->
<!--                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i-->
<!--                        class="ti-twitter theme-cl"></i>Twitter</a></li>-->
<!--                  </ul>-->
<!--                </div>-->

<!--              </form>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="crs_log__footer d-flex justify-content-between mt-0">-->
<!--            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a>-->
<!--            </p></div>-->
<!--            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--endregion 登录-->
    <!-- <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a> -->


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- All Jquery -->
  <!-- ============================================================== -->
</template>

<script>
import {formatterCount} from "@/scripts/common";

export default {
  name: "index",
  data() {
    return {
      content: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.content = require("@/assets/data/index-page.json");
    });
  },
  methods: {
    jump(path) {
      this.$router.push(path)
    },
    formatter(inNum) {
      return formatterCount(inNum);
    }
  }
}
</script>

<style scoped>

</style>
