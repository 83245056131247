<template>
  <!--region 登录-->
  <div
    v-show="showDialog"
    class="modal fade"
    id="login"
    tabindex="-1"
    role="dialog"
    aria-labelledby="loginmodal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl login-pop-form" role="document">
      <div class="modal-content overli" id="loginmodal">
        <div class="modal-header">
          <h5 class="modal-title">登录</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="login-form">
            <form>
              <div class="form-group">
                <label>请打开手机微信扫一扫，扫描二维码登陆~</label>
                <div class="input-with-icon">
                  <div
                    class="qrcode"
                    style="
                      width: 200px;
                      height: 200px;
                      border: 1px solid whitesmoke;
                      margin: 15px auto;
                    "
                    ref="qrcode"
                  ></div>
                </div>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-md full-width theme-bg text-white"
                >
                  刷新二维码
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="crs_log__footer d-flex justify-content-between mt-0">
          <div class="fhg_45"><p class="musrt">其他登录方式将陆续接入</p></div>
        </div>
      </div>
    </div>
  </div>
  <!--endregion 登录-->
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "qr-login",
  data() {
    return {
      qrcode: null,
      showDialog: false,
      ticket: null,
      expires: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    // show () {
    //   this.showDialog = true;
    //   this.$apis.getLoginQRCode().then(res => {
    //     let resData = res.data;
    //     if (resData.success) {
    //       this.ticket = resData.ticket;
    //       this.expires = resData.expire_seconds;
    //       this.generateQR(resData.url);
    //     } else {
    //       this.$error(res.msg);
    //     }
    //   }).catch(err=>{
    //     this.$error(err.message);
    //   });
    // },
    show() {
      this.showDialog = true;
      this.generateQR("1231241212312412123124121231241212312412");
    },
    close() {
      this.showDialog = false;
      this.qrcode = null;
      this.ticket = null;
      this.expires = null;
    },
    generateQR(url) {
      this.$refs.qrcode.innerHTML=""
      this.qrcode = new QRCode(this.$refs.qrcode, {
        text: url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>

<style scoped>
</style>
