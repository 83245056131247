<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-light dark-text">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li class="account-drop">
                  <a href="javascript:void(0);" class="crs_yuo12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="embos_45"><i class="fas fa-shopping-basket"></i><i class="embose_count">4</i></span>
                  </a>
                  <div class="dropdown-menu pull-right animated flipInX">
                    <div class="drp_menu_headr bg-purple">
                      <h4>Wishlist Product</h4>
                    </div>
                    <div class="ground-list ground-hover-list">
                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Web History<small class="float-right text-fade">$120</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Physics Beginning<small class="float-right text-fade">$99</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Computer Fundamental<small class="float-right text-fade">$99</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="grd_thum"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" width="50" alt="" /></div>
                        <div class="ground-content">
                          <h6>Computer Advance<small class="float-right text-fade">$49</small></h6>
                          <a href="#" class="small text-danger">Remove</a>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <button type="button" class="btn theme-bg text-white full-width">Go To Cart</button>
                      </div>

                    </div>
                  </div>
                </li>
                <li class="account-drop">
                  <a href="javascript:void(0);" class="crs_yuo12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="embos_45"><i class="fas fa-bell"></i><i class="embose_count red">3</i></span>
                  </a>
                  <div class="dropdown-menu pull-right animated flipInX">
                    <div class="drp_menu_headr bg-warning">
                      <h4>22 Notifications</h4>
                    </div>
                    <div class="ground-list ground-hover-list">
                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-success">
                          <div class="position-absolute text-success h5 mb-0"><i class="fas fa-user"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Maryam Amiri</a></h6>
                          <small class="text-fade">New User Enrolled in Python</small>
                          <span class="small">Just Now</span>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-danger">
                          <div class="position-absolute text-danger h5 mb-0"><i class="fas fa-comments"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Shilpa Rana</a></h6>
                          <small class="text-fade">Shilpa Send a Message</small>
                          <span class="small">02 Min Ago</span>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-info">
                          <div class="position-absolute text-info h5 mb-0"><i class="fas fa-grin-squint-tears"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Amar Muskali</a></h6>
                          <small class="text-fade">Need Responsive Business Tem...</small>
                          <span class="small">10 Min Ago</span>
                        </div>
                      </div>

                      <div class="ground ground-list-single">
                        <div class="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-light-purple">
                          <div class="position-absolute text-purple h5 mb-0"><i class="fas fa-briefcase"></i></div>
                        </div>

                        <div class="ground-content">
                          <h6><a href="#">Maryam Amiri</a></h6>
                          <small class="text-fade">Next Meeting on Tuesday..</small>
                          <span class="small">15 Min Ago</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </li>

                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12">
                    <span class="embos_45"><i class="fas fa-sign-in-alt"></i></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Hero Banner  Start================================== -->
    <div class="hero_banner image-cover image_bottom h6_bg">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="simple-search-wrap text-left">
              <div class="hero_search-2">
                <h1 class="banner_title mb-4">The Best<br>e-Learning Cources For<br><span class="light">Better Future</span></h1>
                <p class="font-lg mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                <div class="inline_btn">
                  <a href="explore-category.html" class="btn theme-bg text-white">Get Started</a>
                  <a href="explore-category.html" class="btn light_btn">Become An Instructor</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="side_block extream_img">
              <div class="list_crs_img">
                <img src="assets/img/img-1.png" class="img-fluid elsio cirl animate-fl-y" alt="" />
                <img src="assets/img/img-3.png" class="img-fluid elsio arrow animate-fl-x" alt="" />
                <img src="assets/img/img-2.png" class="img-fluid elsio moon animate-fl-x" alt="" />
              </div>
              <img src="assets/img/st-3.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

    <!-- ================================ Tag Award ================================ -->
    <section class="p-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="crp_box fl_color ovr_top">
              <div class="row align-items-center">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141 de"><i class="fa fa-journal-whills"></i></div>
                    <div class="dro_142">
                      <h6>200+ Cources</h6>
                      <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141 de"><i class="fa fa-business-time"></i></div>
                    <div class="dro_142">
                      <h6>Lifetime Access</h6>
                      <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="dro_140">
                    <div class="dro_141 de"><i class="fa fa-user-shield"></i></div>
                    <div class="dro_142">
                      <h6>800k+ Enrolled</h6>
                      <p>Duis aute irure dolor in voluptate velit esse cillum labore .</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- ================================ Tag Award ================================ -->

    <!-- ============================ Cources Start ================================== -->
    <section>
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Explore Featured <span class="theme-cl">Cources</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Development</span>
                  <span class="est st_2">PHP</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">Advance PHP knowledge with laravel to make smart web</h4>
                  <span class="crs_auth"><i>By</i> Adam Wilson</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">99</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Insurence</span>
                  <span class="est st_2">Banking</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">The complete accounting & bank financial course 2021</h4>
                  <span class="crs_auth"><i>By</i> Mike Hussey</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">139</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Software</span>
                  <span class="est st_2">Technology</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">The complete business plan course includes 50 templates</h4>
                  <span class="crs_auth"><i>By</i> Litha Joshi</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">77.99</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="crs_grid_list">

              <div class="crs_grid_list_thumb">
                <a href="course-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid rounded" alt=""></a>
              </div>

              <div class="crs_grid_list_caption">
                <div class="crs_lt_101">
                  <span class="est st_1">Business</span>
                  <span class="est st_2">Finance</span>
                </div>
                <div class="crs_lt_102">
                  <h4 class="crs_tit">Full web designing course with 20 web template designing</h4>
                  <span class="crs_auth"><i>By</i> Adam Wilson</span>
                </div>
                <div class="crs_lt_103">
                  <div class="crs_info_detail">
                    <ul>
                      <li><i class="fa fa-video"></i><span>24 Videos</span></li>
                      <li><i class="fa fa-user"></i><span>10k User</span></li>
                      <li><i class="fa fa-eye"></i><span>92k Views</span></li>
                    </ul>
                  </div>
                </div>
                <div class="crs_flex">
                  <div class="crs_fl_first">
                    <div class="crs_price"><h2><span class="currency">$</span><span class="theme-cl">129</span></h2></div>
                  </div>
                  <div class="crs_fl_last">
                    <div class="crs_linkview"><a href="course-detail.html" class="btn btn_view_detail theme-bg text-light">Enroll Now</a></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8 mt-2">
            <div class="text-center"><a href="grid-layout-with-sidebar.html" class="btn btn-md theme-bg-light theme-cl">Explore More Cources</a></div>
          </div>
        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Cources End ================================== -->

    <!-- ============================ Categories Start ================================== -->
    <section class="min gray">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Select Your <span class="theme-cl">Categories</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-code"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Development</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-window-restore"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Web Designing</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-leaf"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Lifestyle</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-heartbeat"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Health & Fitness</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-landmark"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Gov. Exams</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-photo-video"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Photography</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-stamp"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Finance & Accounting</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="cates_crs_wrip">
              <div class="crs_trios">
                <div class="crs_cate_icon"><i class="fa fa-school"></i></div>
                <div class="crs_cate_link"><a href="grid-layout-with-sidebar.html">View Cources</a></div>
              </div>
              <div class="crs_capt_cat">
                <h4>Office Productivity</h4>
                <p>At vero eos et accusamus et iusto odio dignissimos.</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ Categories End ================================== -->

    <!-- ============================ Top Instructor Start ================================== -->
    <section>
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-10 text-center">
            <div class="sec-heading center mb-4">
              <h2>Our Top <span class="theme-cl">Instructors</span></h2>
              <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="tutor-slide">

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid theme-light shadow_none">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt=""></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag dark"><span>Sanskrit Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Luella J. Robles</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid theme-light shadow_none">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt=""></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag dark"><span>Math Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">David E. Lampkin</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid theme-light shadow_none">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt=""></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag dark"><span>History Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Michael B. Maxwell</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid theme-light shadow_none">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt=""></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag dark"><span>Physics Teacher</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Daniel D. Couse</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid theme-light shadow_none">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt=""></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag dark"><span>PHP Developer</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Linda R. Gibson</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Item -->
              <div class="lios_item">
                <div class="crs_trt_grid theme-light shadow_none">
                  <div class="crs_trt_thumb">
                    <a href="instructor-detail.html" class="crs_trt_thum_link"><img src="https://via.placeholder.com/700x550" class="img-fluid" alt=""></a>
                  </div>
                  <div class="crs_trt_caption large">
                    <div class="instructor_tag dark"><span>Chemistry Expert</span></div>
                    <div class="instructor_title"><h4><a href="instructor-detail.html">Douglas M. Mikel</a></h4></div>
                    <div class="trt_rate_inf">
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star filled"></i>
                      <i class="fa fa-star-half filled"></i>
                      <span class="alt_rates">(244 Reviews)</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- ============================ Top Instructor End ================================== -->

    <!-- ============================ How It Works Start ================================== -->
    <section class="min gray">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>How It <span class="theme-cl">Works?</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-5">

          <!-- Single Location -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="wrk_grid">
              <div class="wrk_grid_ico">
                <i class="fa fa-search-location"></i>
              </div>
              <div class="wrk_caption">
                <h4>Find Cources</h4>
                <p>We have helped over 3,400 new students to get into the most popular tech teams.</p>
              </div>
            </div>
          </div>

          <!-- Single Location -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="wrk_grid active">
              <div class="wrk_grid_ico">
                <i class="fa fa-calendar-week"></i>
              </div>
              <div class="wrk_caption">
                <h4>Book Your Seat</h4>
                <p>We have helped over 3,400 new students to get into the most popular tech teams.</p>
              </div>
            </div>
          </div>

          <!-- Single Location -->
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="wrk_grid">
              <div class="wrk_grid_ico">
                <i class="fa fa-award"></i>
              </div>
              <div class="wrk_caption">
                <h4>Get Certificate</h4>
                <p>We have helped over 3,400 new students to get into the most popular tech teams.</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ How It Works End ================================== -->

    <!-- ============================ article Start ================================== -->
    <section class="min">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="sec-heading center">
              <h2>Latest News & <span class="theme-cl">Articles</span></h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">

          <!-- Single blog Grid -->
          <div class="col-lg-4 col-md-6">
            <div class="blg_grid_box">
              <div class="blg_grid_thumb">
                <a href="blog-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt=""></a>
              </div>
              <div class="blg_grid_caption">
                <div class="blg_tag dark"><span>Marketing</span></div>
                <div class="blg_title"><h4><a href="blog-detail.html">How To Improove Digital Marketing for Fast SEO</a></h4></div>
                <div class="blg_desc"><p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum</p></div>
                <div class="blg_more"><a href="blog-detail.html">Reading Continues</a></div>
              </div>
            </div>
          </div>

          <!-- Single blog Grid -->
          <div class="col-lg-4 col-md-6">
            <div class="blg_grid_box">
              <div class="blg_grid_thumb">
                <a href="blog-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt=""></a>
              </div>
              <div class="blg_grid_caption">
                <div class="blg_tag dark"><span>Marketing</span></div>
                <div class="blg_title"><h4><a href="blog-detail.html">How To Improove Digital Marketing for Fast SEO</a></h4></div>
                <div class="blg_desc"><p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum</p></div>
                <div class="blg_more"><a href="blog-detail.html">Reading Continues</a></div>
              </div>
            </div>
          </div>

          <!-- Single blog Grid -->
          <div class="col-lg-4 col-md-6">
            <div class="blg_grid_box">
              <div class="blg_grid_thumb">
                <a href="blog-detail.html"><img src="https://via.placeholder.com/1200x800" class="img-fluid" alt=""></a>
              </div>
              <div class="blg_grid_caption">
                <div class="blg_tag dark"><span>Marketing</span></div>
                <div class="blg_title"><h4><a href="blog-detail.html">How To Improove Digital Marketing for Fast SEO</a></h4></div>
                <div class="blg_desc"><p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum</p></div>
                <div class="blg_more"><a href="blog-detail.html">Reading Continues</a></div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    <div class="clearfix"></div>
    <!-- ============================ article End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "home-6"
}
</script>

<style scoped>

</style>
