<template>

  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">
    <!-- ============================ User Dashboard ================================== -->
    <section class="error-wrap">
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-lg-6 col-md-10">
            <div class="text-center">

              <img src="../assets/img/404.png" class="img-fluid" alt="">
              <p>Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortor morbi ultricies laoreet ullamcorper phasellus semper</p>
              <a class="btn theme-bg text-white btn-md" href="index.html">返回主页</a>

            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- ============================ User Dashboard End ================================== -->


    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>
