<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">

    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->
    <!-- Start Navigation -->
    <div class="header header-light head-shadow">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
              <img src="assets/img/logo.png" class="logo" alt="" />
            </a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                <li>
                  <a href="javascript:void(0);" data-toggle="modal" data-target="#login" class="crs_yuo12 w-auto text-white theme-bg">
                    <span class="embos_45"><i class="fas fa-sign-in-alt mr-1"></i>Sign In</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper">
            <ul class="nav-menu">

              <li class="active"><a href="#">Home<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="index.html">Home 1</a></li>
                  <li><a href="home-2.html">Home 2</a></li>
                  <li><a href="home-3.html">Home 3</a></li>
                  <li><a href="home-4.html">Home 4</a></li>
                  <li><a href="home-5.html">Home 5</a></li>
                  <li><a href="home-6.html">Home 6</a></li>
                  <li><a href="home-7.html">Home 7</a></li>
                </ul>
              </li>

              <li><a href="#">Courses<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Search Courses in Grid<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="grid-layout-with-sidebar.html">Grid Layout Style 1</a></li>
                      <li><a href="grid-layout-with-sidebar-2.html">Grid Layout Style 2</a></li>
                      <li><a href="grid-layout-with-sidebar-3.html">Grid Layout Style 3</a></li>
                      <li><a href="grid-layout-with-sidebar-4.html">Grid Layout Style 4</a></li>
                      <li><a href="grid-layout-with-sidebar-5.html">Grid Layout Style 5</a></li>
                      <li><a href="grid-layout-full.html">Grid Full Width</a></li>
                      <li><a href="grid-layout-full-2.html">Grid Full Width 2</a></li>
                      <li><a href="grid-layout-full-3.html">Grid Full Width 3</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Search Courses in List<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="list-layout-with-sidebar.html">List Layout Style 1</a></li>
                      <li><a href="list-layout-with-full.html">List Full Width</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Courses Detail<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="course-detail.html">Course Detail 01</a></li>
                      <li><a href="course-detail-2.html">Course Detail 02</a></li>
                      <li><a href="course-detail-3.html">Course Detail 03</a></li>
                      <li><a href="course-detail-4.html">Course Detail 04</a></li>
                    </ul>
                  </li>

                  <li><a href="explore-category.html">Explore Category</a></li>
                  <li><a href="find-instructor.html">Find Instructor</a></li>
                  <li><a href="instructor-detail.html">Instructor Detail</a></li>
                </ul>
              </li>

              <li><a href="#">Pages<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="#">Shop Pages<span class="submenu-indicator"></span></a>
                    <ul class="nav-dropdown nav-submenu">
                      <li><a href="shop-with-sidebar.html">Shop Sidebar Left</a></li>
                      <li><a href="shop-with-right-sidebar.html">Shop Sidebar Right</a></li>
                      <li><a href="list-shop-with-sidebar.html">Shop List Style</a></li>
                      <li><a href="wishlist.html">Wishlist</a></li>
                      <li><a href="checkout.html">Checkout</a></li>
                      <li><a href="product-detail.html">Product Detail</a></li>
                    </ul>
                  </li>
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="contact.html">Say Hello</a></li>
                  <li><a href="blogs.html">Blog Style</a></li>
                  <li><a href="pricing.html">Pricing</a></li>
                  <li><a href="404.html">404 Page</a></li>
                  <li><a href="component.html">Elements</a></li>
                  <li><a href="faq.html">FAQs</a></li>
                  <li><a href="login.html">Login</a></li>
                  <li><a href="signup.html">Signup</a></li>
                  <li><a href="forgot.html">Forgot</a></li>
                </ul>
              </li>

              <li><a href="dashboard.html">Dashboard</a></li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">

              <li>
                <a href="#" class="alio_green" data-toggle="modal" data-target="#login">
                  <i class="fas fa-sign-in-alt mr-1"></i><span class="dn-lg">Sign In</span>
                </a>
              </li>
              <li class="add-listing theme-bg">
                <a href="signup.html" class="text-white">Get Started</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Page Title Start================================== -->
    <section class="page-title gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">

            <div class="breadcrumbs-wrap">
              <h1 class="breadcrumb-title">Elements</h1>
              <nav class="transparent">
                <ol class="breadcrumb p-0">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active theme-cl" aria-current="page">Components</li>
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Agency List Start ================================== -->
    <section class="pt-0">

      <div class="container">

        <!-- row Start -->
        <div class="row">

          <div class="col-lg-6 col-md-12 col-sm-12">
            <h4>Tabs Examples</h4>
            <div class="custom-tab customize-tab tabs_creative">
              <ul class="nav nav-tabs pb-2 b-0" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12">
            <h4>Accordion Examples</h4>
            <div id="accordionExample" class="accordion">

              <!-- Part 1 -->
              <div class="card">
                <div id="headingOne" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" class="d-block position-relative text-dark collapsible-link py-2">How To Learn Web Designing Step by Step</a></h6>
                </div>
                <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" class="collapse show">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

              <!-- Part 2 -->
              <div class="card">
                <div id="headingTwo" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" class="d-block position-relative collapsed text-dark collapsible-link py-2">Learn Web Designing in Basic Level</a></h6>
                </div>
                <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample" class="collapse">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

              <!-- Part 3 -->
              <div class="card">
                <div id="headingThree" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" class="d-block position-relative collapsed text-dark collapsible-link py-2">Learn Web Designing in Advance Level</a></h6>
                </div>
                <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample" class="collapse">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

              <!-- Part 04 -->
              <div class="card">
                <div id="headingFour" class="card-header bg-white shadow-sm border-0">
                  <h6 class="mb-0 accordion_title"><a href="#" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" class="d-block position-relative collapsed text-dark collapsible-link py-2">How To Become Succes in Designing & Development?</a></h6>
                </div>
                <div id="collapseFour" aria-labelledby="headingFour" data-parent="#accordionExample" class="collapse">
                  <div class="card-body pl-3 pr-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <!-- /row -->

        <!-- row Start -->
        <div class="row mt-4">

          <div class="col-lg-6 col-md-12 col-sm-12">
            <h4>Typography</h4>
            <h1>Heading One</h1>
            <h2>Heading Two</h2>
            <h3>Heading Three</h3>
            <h4>Heading Four</h4>
            <h5>Heading Five</h5>
            <h6>Heading Six</h6>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12">
            <h4>Buttons</h4>
            <button type="submit" class="btn theme-bg">Simple button</button><br>
            <button type="submit" class="btn theme-bg btn-rounded">Simple button</button>
            <button type="submit" class="btn theme-bg btn-md">Midium Button</button><br>
            <button type="submit" class="btn bg-dark btn-lg">Large Button</button>
            <button type="submit" class="btn btn-outline-theme">Outline Button</button><br><br>
            <a href="#" class="btn btn-theme">Simple button</a><br>
            <a href="#" class="btn btn-theme-2">Simple button</a>
          </div>

        </div>
        <!-- /row -->

        <!-- row Start -->
        <div class="row mt-4">

          <div class="col-lg-6 col-md-12 col-sm-12">
            <h4>Shadow & Simple Inputbox</h4>

            <div class="input-with-icon">
              <input type="text" class="form-control" placeholder="Search Your Cources">
              <i class="ti-search"></i>
            </div>

            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control simple">
            </div>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12">
            <h4>Checkbox & Radio buttons</h4>
            <ul class="no-ul-list">
              <li>
                <input id="a-1" class="checkbox-custom" name="a-1" type="checkbox">
                <label for="a-1" class="checkbox-custom-label">Air Condition</label>
              </li>
              <li>
                <input id="a-2" class="checkbox-custom" name="a-2" type="checkbox">
                <label for="a-2" class="checkbox-custom-label">Bedding</label>
              </li>

            </ul>

            <ul class="no-ul-list">
              <li>
                <input id="a-p" class="checkbox-custom" name="a-p" type="radio">
                <label for="a-p" class="checkbox-custom-label">Air Condition</label>
              </li>
              <li>
                <input id="a-c" class="checkbox-custom" name="a-c" type="radio">
                <label for="a-c" class="checkbox-custom-label">Bedding</label>
              </li>

            </ul>
          </div>

        </div>
        <!-- /row -->

        <div class="row mt-3">
          <div class="col-lg-12 col-md-12">
            <h4>Lists Style</h4>
          </div>

          <!-- Buttons Styles -->
          <div class="col-lg-4 col-md-4">
            <ul class="lists lists-1">
              <li>A simple light alert</li>
              <li>A simple light alert</li>
              <li>A simple light alert</li>
              <li>A simple light alert</li>
            </ul>
          </div>

          <!-- Buttons Styles -->
          <div class="col-lg-4 col-md-4">
            <ul class="lists lists-2">
              <li>A simple light alert</li>
              <li>A simple light alert</li>
              <li>A simple light alert</li>
              <li>A simple light alert</li>
            </ul>
          </div>

          <!-- Buttons Styles -->
          <div class="col-lg-4 col-md-4">
            <ul class="lists lists-3">
              <li>A simple light alert</li>
              <li>A simple light alert</li>
              <li>A simple light alert</li>
              <li>A simple light alert</li>
            </ul>
          </div>

        </div>

      </div>

    </section>
    <!-- ============================ Agency List End ================================== -->

    <!-- ============================ Call To Action ================================== -->
    <section class="theme-bg call_action_wrap-wrap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">

            <div class="call_action_wrap">
              <div class="call_action_wrap-head">
                <h3>Do You Have Questions ?</h3>
                <span>We'll help you to grow your career and growth.</span>
              </div>
              <a href="#" class="btn btn-call_action_wrap">Contact Us Today</a>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Call To Action End ================================== -->

    <!-- ============================ Footer Start ================================== -->
    <footer class="dark-footer skin-dark-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">

            <div class="col-lg-5 col-md-5">
              <div class="footer_widget">
                <img src="assets/img/logo-light.png" class="img-footer small mb-2" alt="" />
                <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                <div class="foot-news-last">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Email Address">
                    <div class="input-group-append">
                      <button type="button" class="input-group-text theme-bg b-0 text-light">Subscribe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-7 ml-auto">
              <div class="row">

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Layouts</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Home Page</a></li>
                      <li><a href="#">About Page</a></li>
                      <li><a href="#">Service Page</a></li>
                      <li><a href="#">Property Page</a></li>
                      <li><a href="#">Contact Page</a></li>
                      <li><a href="#">Single Blog</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">All Sections</h4>
                    <ul class="footer-menu">
                      <li><a href="#">Headers<span class="new">New</span></a></li>
                      <li><a href="#">Features</a></li>
                      <li><a href="#">Attractive<span class="new">New</span></a></li>
                      <li><a href="#">Testimonials</a></li>
                      <li><a href="#">Videos</a></li>
                      <li><a href="#">Footers</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="footer_widget">
                    <h4 class="widget_title">Company</h4>
                    <ul class="footer-menu">
                      <li><a href="#">About</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Affiliate</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Changelog<span class="update">Update</span></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">© 2021 SkillUp. Designd By <a href="http://www.bootstrapmb.com">ThemezHub</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-xl login-pop-form" role="document">
        <div class="modal-content overli" id="loginmodal">
          <div class="modal-header">
            <h5 class="modal-title">Login Your Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-form">
              <form>

                <div class="form-group">
                  <label>User Name</label>
                  <div class="input-with-icon">
                    <input type="text" class="form-control" placeholder="User or email">
                    <i class="ti-user"></i>
                  </div>
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <div class="input-with-icon">
                    <input type="password" class="form-control" placeholder="*******">
                    <i class="ti-unlock"></i>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="admin" class="checkbox-custom" name="admin" type="checkbox">
                    <label for="admin" class="checkbox-custom-label">Admin</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="student" class="checkbox-custom" name="student" type="checkbox" checked>
                    <label for="student" class="checkbox-custom-label">Student</label>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-4">
                    <input id="instructor" class="checkbox-custom" name="instructor" type="checkbox">
                    <label for="instructor" class="checkbox-custom-label">Tutors</label>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md full-width theme-bg text-white">Login</button>
                </div>

                <div class="rcs_log_125 pt-2 pb-3">
                  <span>Or Login with Social Info</span>
                </div>
                <div class="rcs_log_126 full">
                  <ul class="social_log_45 row">
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-facebook text-info"></i>Facebook</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-google text-danger"></i>Google</a></li>
                    <li class="col-xl-4 col-lg-4 col-md-4 col-4"><a href="javascript:void(0);" class="sl_btn"><i class="ti-twitter theme-cl"></i>Twitter</a></li>
                  </ul>
                </div>

              </form>
            </div>
          </div>
          <div class="crs_log__footer d-flex justify-content-between mt-0">
            <div class="fhg_45"><p class="musrt">Don't have account? <a href="signup.html" class="theme-cl">SignUp</a></p></div>
            <div class="fhg_45"><p class="musrt"><a href="forgot.html" class="text-danger">Forgot Password?</a></p></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>


  </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->

</template>

<script>
export default {
  name: "component"
}
</script>

<style scoped>

</style>
