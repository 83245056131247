<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <div id="main-wrapper">
    <!-- ============================ Page Title Start================================== -->
    <section class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="breadcrumbs-wrap">
              <h1 class="breadcrumb-title">{{ title }}</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 bg-white">
                  <li class="breadcrumb-item">
                    <a @click="jump('/')">主页</a>
                  </li>
                  <li
                    class="breadcrumb-item active theme-cl"
                    aria-current="page"
                  >
                    选择模板
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ All Cources ================================== -->
    <section class="gray">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="page-sidebar p-0">
              <a
                class="filter_links"
                data-toggle="collapse"
                href="#fltbox"
                role="button"
                aria-expanded="false"
                aria-controls="fltbox"
                >Open Advance Filter<i class="fa fa-sliders-h ml-2"></i
              ></a>
              <div class="collapse" id="fltbox">
                <!-- Find New Property -->
                <div class="sidebar-widgets p-4">
                  <div class="form-group">
                    <div class="input-with-icon">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="搜索您想要的模板"
                      />
                      <i class="ti-search"></i>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="simple-input">
                      <select id="cates" class="form-control" aria-placeholder="请选择模板类型">
                        <option value="">-请选择-</option>
                        <option value="1">日程小管家</option>
                        <option value="2">吃药小助手</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group" v-for="(item, index) in Object.keys(options)" :key="'selections_'+index">
                    <h6>{{ options[item].title }}</h6>
                    <ul class="no-ul-list mb-3">
                      <li v-for="(opt, optInd) in options[item].opts" :key="'opts_'+item+'_'+optInd">
                        <input
                          id="aa-41"
                          class="checkbox-custom"
                          name="aa-41"
                          type="checkbox"
                          @change="check(item, opt.text)"
                        />
                        <label for="aa-41" class="checkbox-custom-label"
                          >{{ opt.text }}
                          <!-- <i class="count">12</i> -->
                          </label>
                      </li>
                    </ul>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 pt-4">
                      <button class="btn theme-bg rounded full-width">
                        搜索
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Sidebar End -->
          </div>

          <!-- Content -->
          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="short_wraping">
                  <div
                    class="row m-0 align-items-center justify-content-between"
                  >
                    <div class="col-lg-4 col-md-5 col-sm-12 col-sm-6">
                      <div class="shorting_pagination_laft">
                        <h6 class="m-0">Showing 1-25 of 72</h6>
                      </div>
                    </div>

                    <div class="col-lg-8 col-md-7 col-sm-12 col-sm-6">
                      <div class="dlks_152">
                        <div class="shorting-right mr-2">
                          <label>Short By:</label>
                          <div class="dropdown show">
                            <a
                              class="btn btn-filter dropdown-toggle"
                              href="#"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span class="selection">Most Rated</span>
                            </a>
                            <div class="drp-select dropdown-menu">
                              <a
                                class="dropdown-item"
                                href="JavaScript:Void(0);"
                                >Most Rated</a
                              >
                              <a
                                class="dropdown-item"
                                href="JavaScript:Void(0);"
                                >Most Viewd</a
                              >
                              <a
                                class="dropdown-item"
                                href="JavaScript:Void(0);"
                                >News Listings</a
                              >
                              <a
                                class="dropdown-item"
                                href="JavaScript:Void(0);"
                                >High Rated</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="lmk_485">
                          <ul class="shorting_grid">
                            <li class="list-inline-item">
                              <a href="grid-layout-full.html" class="active"
                                ><span class="ti-layout-grid2"></span
                              ></a>
                            </li>
                            <li class="list-inline-item">
                              <a
                                href="list-layout-with-full.html"
                                class="active"
                                ><span class="ti-view-list"></span
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <!-- Single Grid -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="crs_lt_2">
                  <div class="crs_lt_boxes">
                    <div class="crs_grid_list_thumb">
                      <a href="course-detail.html"
                        ><img
                          src="https://via.placeholder.com/1200x800"
                          class="img-fluid rounded"
                          alt=""
                      /></a>
                    </div>

                    <div class="crs_grid_list_caption">
                      <div class="crs_lt_101">
                        <span class="est st_1">Design</span>
                        <span class="est st_2">Banking</span>
                      </div>
                      <div class="crs_lt_102">
                        <h4 class="crs_tit">
                          How To Learn UI/UX Design pattern for succesfull
                          software Applications
                        </h4>
                        <span class="crs_auth"><i>By</i> Adam Wilson</span>
                      </div>
                      <div class="crs_lt_103">
                        <div class="crs_info_detail">
                          <ul>
                            <li>
                              <i class="fa fa-video"></i><span>24 Videos</span>
                            </li>
                            <li>
                              <i class="fa fa-user"></i><span>10k User</span>
                            </li>
                            <li>
                              <i class="fa fa-eye"></i><span>92k Views</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="crs_flex">
                        <div class="crs_fl_first">
                          <div class="crs_price">
                            <h2>
                              <span class="currency">$</span
                              ><span class="theme-cl">149</span>
                            </h2>
                          </div>
                        </div>
                        <div class="crs_fl_last">
                          <div class="crs_linkview">
                            <a
                              href="course-detail.html"
                              class="btn btn_view_detail theme-bg text-light"
                              >Enroll Now</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="lt_cr_footer">
                    <div class="lt_cr_footer_first">
                      <ul class="lt_cr_list">
                        <li>
                          <a href="#" class="prv_li"
                            ><i class="fa fa-play text-success"></i>View
                            Preview</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="lt_cr_footer_last">
                      <ul class="lt_cr_list">
                        <li>
                          <i class="fa fa-lightbulb text-info"></i>Beginning
                        </li>
                        <li>
                          <i class="fa fa-hourglass-half text-warning"></i
                          >01:42:10
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Grid -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="crs_lt_2">
                  <div class="crs_lt_boxes">
                    <div class="crs_grid_list_thumb">
                      <a href="course-detail.html"
                        ><img
                          src="https://via.placeholder.com/1200x800"
                          class="img-fluid rounded"
                          alt=""
                      /></a>
                    </div>

                    <div class="crs_grid_list_caption">
                      <div class="crs_lt_101">
                        <span class="est st_1">Design</span>
                        <span class="est st_2">Banking</span>
                      </div>
                      <div class="crs_lt_102">
                        <h4 class="crs_tit">
                          How To Learn UI/UX Design pattern for succesfull
                          software Applications
                        </h4>
                        <span class="crs_auth"><i>By</i> Adam Wilson</span>
                      </div>
                      <div class="crs_lt_103">
                        <div class="crs_info_detail">
                          <ul>
                            <li>
                              <i class="fa fa-video"></i><span>24 Videos</span>
                            </li>
                            <li>
                              <i class="fa fa-user"></i><span>10k User</span>
                            </li>
                            <li>
                              <i class="fa fa-eye"></i><span>92k Views</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="crs_flex">
                        <div class="crs_fl_first">
                          <div class="crs_price">
                            <h2>
                              <span class="currency">$</span
                              ><span class="theme-cl">149</span>
                            </h2>
                          </div>
                        </div>
                        <div class="crs_fl_last">
                          <div class="crs_linkview">
                            <a
                              href="course-detail.html"
                              class="btn btn_view_detail theme-bg text-light"
                              >Enroll Now</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="lt_cr_footer">
                    <div class="lt_cr_footer_first">
                      <ul class="lt_cr_list">
                        <li>
                          <a href="#" class="prv_li"
                            ><i class="fa fa-play text-success"></i>View
                            Preview</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="lt_cr_footer_last">
                      <ul class="lt_cr_list">
                        <li>
                          <i class="fa fa-lightbulb text-info"></i>Beginning
                        </li>
                        <li>
                          <i class="fa fa-hourglass-half text-warning"></i
                          >01:42:10
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Grid -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="crs_lt_2">
                  <div class="crs_lt_boxes">
                    <div class="crs_grid_list_thumb">
                      <a href="course-detail.html"
                        ><img
                          src="https://via.placeholder.com/1200x800"
                          class="img-fluid rounded"
                          alt=""
                      /></a>
                    </div>

                    <div class="crs_grid_list_caption">
                      <div class="crs_lt_101">
                        <span class="est st_1">Design</span>
                        <span class="est st_2">Banking</span>
                      </div>
                      <div class="crs_lt_102">
                        <h4 class="crs_tit">
                          How To Learn UI/UX Design pattern for succesfull
                          software Applications
                        </h4>
                        <span class="crs_auth"><i>By</i> Adam Wilson</span>
                      </div>
                      <div class="crs_lt_103">
                        <div class="crs_info_detail">
                          <ul>
                            <li>
                              <i class="fa fa-video"></i><span>24 Videos</span>
                            </li>
                            <li>
                              <i class="fa fa-user"></i><span>10k User</span>
                            </li>
                            <li>
                              <i class="fa fa-eye"></i><span>92k Views</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="crs_flex">
                        <div class="crs_fl_first">
                          <div class="crs_price">
                            <h2>
                              <span class="currency">$</span
                              ><span class="theme-cl">149</span>
                            </h2>
                          </div>
                        </div>
                        <div class="crs_fl_last">
                          <div class="crs_linkview">
                            <a
                              href="course-detail.html"
                              class="btn btn_view_detail theme-bg text-light"
                              >Enroll Now</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="lt_cr_footer">
                    <div class="lt_cr_footer_first">
                      <ul class="lt_cr_list">
                        <li>
                          <a href="#" class="prv_li"
                            ><i class="fa fa-play text-success"></i>View
                            Preview</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="lt_cr_footer_last">
                      <ul class="lt_cr_list">
                        <li>
                          <i class="fa fa-lightbulb text-info"></i>Beginning
                        </li>
                        <li>
                          <i class="fa fa-hourglass-half text-warning"></i
                          >01:42:10
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Grid -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="crs_lt_2">
                  <div class="crs_lt_boxes">
                    <div class="crs_grid_list_thumb">
                      <a href="course-detail.html"
                        ><img
                          src="https://via.placeholder.com/1200x800"
                          class="img-fluid rounded"
                          alt=""
                      /></a>
                    </div>

                    <div class="crs_grid_list_caption">
                      <div class="crs_lt_101">
                        <span class="est st_1">Design</span>
                        <span class="est st_2">Banking</span>
                      </div>
                      <div class="crs_lt_102">
                        <h4 class="crs_tit">
                          How To Learn UI/UX Design pattern for succesfull
                          software Applications
                        </h4>
                        <span class="crs_auth"><i>By</i> Adam Wilson</span>
                      </div>
                      <div class="crs_lt_103">
                        <div class="crs_info_detail">
                          <ul>
                            <li>
                              <i class="fa fa-video"></i><span>24 Videos</span>
                            </li>
                            <li>
                              <i class="fa fa-user"></i><span>10k User</span>
                            </li>
                            <li>
                              <i class="fa fa-eye"></i><span>92k Views</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="crs_flex">
                        <div class="crs_fl_first">
                          <div class="crs_price">
                            <h2>
                              <span class="currency">$</span
                              ><span class="theme-cl">149</span>
                            </h2>
                          </div>
                        </div>
                        <div class="crs_fl_last">
                          <div class="crs_linkview">
                            <a
                              href="course-detail.html"
                              class="btn btn_view_detail theme-bg text-light"
                              >Enroll Now</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="lt_cr_footer">
                    <div class="lt_cr_footer_first">
                      <ul class="lt_cr_list">
                        <li>
                          <a href="#" class="prv_li"
                            ><i class="fa fa-play text-success"></i>View
                            Preview</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="lt_cr_footer_last">
                      <ul class="lt_cr_list">
                        <li>
                          <i class="fa fa-lightbulb text-info"></i>Beginning
                        </li>
                        <li>
                          <i class="fa fa-hourglass-half text-warning"></i
                          >01:42:10
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Grid -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="crs_lt_2">
                  <div class="crs_lt_boxes">
                    <div class="crs_grid_list_thumb">
                      <a href="course-detail.html"
                        ><img
                          src="https://via.placeholder.com/1200x800"
                          class="img-fluid rounded"
                          alt=""
                      /></a>
                    </div>

                    <div class="crs_grid_list_caption">
                      <div class="crs_lt_101">
                        <span class="est st_1">Design</span>
                        <span class="est st_2">Banking</span>
                      </div>
                      <div class="crs_lt_102">
                        <h4 class="crs_tit">
                          How To Learn UI/UX Design pattern for succesfull
                          software Applications
                        </h4>
                        <span class="crs_auth"><i>By</i> Adam Wilson</span>
                      </div>
                      <div class="crs_lt_103">
                        <div class="crs_info_detail">
                          <ul>
                            <li>
                              <i class="fa fa-video"></i><span>24 Videos</span>
                            </li>
                            <li>
                              <i class="fa fa-user"></i><span>10k User</span>
                            </li>
                            <li>
                              <i class="fa fa-eye"></i><span>92k Views</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="crs_flex">
                        <div class="crs_fl_first">
                          <div class="crs_price">
                            <h2>
                              <span class="currency">$</span
                              ><span class="theme-cl">149</span>
                            </h2>
                          </div>
                        </div>
                        <div class="crs_fl_last">
                          <div class="crs_linkview">
                            <a
                              href="course-detail.html"
                              class="btn btn_view_detail theme-bg text-light"
                              >Enroll Now</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="lt_cr_footer">
                    <div class="lt_cr_footer_first">
                      <ul class="lt_cr_list">
                        <li>
                          <a href="#" class="prv_li"
                            ><i class="fa fa-play text-success"></i>View
                            Preview</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="lt_cr_footer_last">
                      <ul class="lt_cr_list">
                        <li>
                          <i class="fa fa-lightbulb text-info"></i>Beginning
                        </li>
                        <li>
                          <i class="fa fa-hourglass-half text-warning"></i
                          >01:42:10
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ul class="pagination p-center">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span class="ti-arrow-left"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item active">
                    <a class="page-link" href="#">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">...</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">18</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span class="ti-arrow-right"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ All Cources ================================== -->

   </div>
  <!-- ============================================================== -->
  <!-- End Wrapper -->
  <!-- ============================================================== -->
</template>

<script>
export default {
  name: "templates",
  data() {
    return {
      title: "",
      type: "",
      params: {
        voiceType: "0",
        type: "",
        toWhom: []
      },
      options: {
        voiceType: {
          title: "声音类型",
          opts: []},
        toWhom: {
           title: "提醒对象",
          opts: []},
      }
    };
  },
  updated () {
    this.$nextTick(() => {
      this.init();
    });
  },
  mounted() {
    this.$nextTick(() => {
     this.init();
    });
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    check (optionsType, checked) {
      this.params[optionsType] = checked;
    },
    /**
     * 初始化
     */
    init () {
      let routeParams = this.$route.params;
      this.type = routeParams.type ? routeParams.type : "";
      this.title = routeParams.title ? routeParams.title : "";
      const dicts = require("@/assets/data/dictionary.json");
      this.options.voiceType.opts = dicts.voiceType;
      this.options.toWhom.opts = dicts.toWhom;
    },
    /**
     * 加载数据
     */
    fetchData () {

    },
  },
};
</script>

<style scoped>
</style>
